import * as MutationsType from "./mutationsType";

/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_AGENT](state, payload) {
    state.agent = payload;
  },
  [MutationsType.SET_AGENTS](state, payload) {
    state.agents = payload;
  },
  [MutationsType.SET_SELLER_AGENTS](state, payload) {
    state.sellerAgents = payload;
  },
  [MutationsType.SET_AGENT_AUTHENTICATED](state, payload) {
    state.agentAuthenticated = payload;
  },
  [MutationsType.SET_AGENT_SELECTED](state, payload) {
    state.agentSelected = payload;
  },
};
