var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [_c("v-card", { staticClass: "pb-2" }, [_c("Notifications")], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "pt-5 mt-5", attrs: { cols: "12" } },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "accent", text: "" },
              on: { click: _vm.signout }
            },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("fas fa-sign-out-alt")
              ]),
              _vm._v(" Cerrar sesión\n    ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }