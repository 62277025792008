<template>
  <v-app id="calendar">
    <v-container v-if="getAgents">
      <v-row>
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <v-row>
            <v-col cols="12">
              <h2>Calendario de eventos</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-sheet height="64">
                <v-toolbar flat color="white">
                  <v-btn outlined class="mr-4" color="primary" @click="setToday">
                    Hoy
                  </v-btn>
                  <v-btn fab text small @click="prev">
                    <v-icon small>fas fa-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text small @click="next">
                    <v-icon small>fas fa-chevron-right</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ title }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <span class="pr-2">Ver: </span>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn outlined color="primary" v-on="on">
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right small>fas fa-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Día</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Semana</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Mes</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet v-if="getEvents" height="600">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="getEvents"
                  :event-color="getEventColor"
                  :event-margin-bottom="3"
                  :now="today"
                  :type="type"
                  locale="es"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @change="updateRange"
                ></v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  full-width
                  offset-x
                  offset-y
                  max-width="300px"
                >
                  <v-card color="grey lighten-4" flat>
                    <v-toolbar :color="getEventColor(selectedEvent)" dark>
                      <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="selectedOpen = false">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="py-3">
                          <p class="subtitle-2 mb-0">{{ selectedEvent.details }}</p>
                        </v-col>
                        <v-col v-if="selectedEvent.ticket_id" cols="12" class="text-left pb-4">
                          <v-btn text small :to="`/ticket/${selectedEvent.ticket_id}`" target="_blank" color="primary">
                            Ver detalle del ticket
                          </v-btn>
                        </v-col>
                        <v-col v-if="getAgents[selectedEvent.agent_id]" cols="12" sm="6" class="d-flex py-0 my-2">
                          <v-avatar size="40px" color="black">
                            <img
                              v-if="getAgents[selectedEvent.agent_id].photo"
                              :src="getAgents[selectedEvent.agent_id].photo"
                              alt="Avatar"
                            />
                            <span v-else class="white--text headline">
                              {{ getAgents[selectedEvent.agent_id].full_name.substring(0, 1) }}
                            </span>
                          </v-avatar>
                          <div class="pl-2">
                            <h6 class="caption font-weight-bold">Encargado</h6>
                            <h5 class="body-2">{{ getAgents[selectedEvent.agent_id].full_name }}</h5>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="text-right">
                          <v-btn :to="`/contact/${selectedEvent.contact_id}`" target="_blank" outlined color="primary">
                            Ver contacto
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Calendar",
  data() {
    return {
      today: this.$moment().format("YYYY-MM-DD"),
      focus: this.$moment().format("YYYY-MM-DD"),
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
    };
  },
  computed: {
    ...mapGetters(["getEvents", "getAgents"]),
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      switch (this.type) {
        case "month":
          return `${startMonth} ${start.year}`;
        case "week":
        case "4day":
          return `${start.day} al ${suffixMonth} ${end.day} de ${startMonth} ${"del " + start.year}`;
        case "day":
          return `${start.day} de ${startMonth} del ${start.year}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  async created() {
    await this.getAllEvents();
    await this.getAllAgents();
    if (this.$refs.calendar) this.$refs.calendar.checkChange();
  },
  methods: {
    ...mapActions(["getAllEvents", "getAllAgents"]),
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      let color_default = "secondary";
      if (this.getAgents[event.agent_id] && this.getAgents[event.agent_id].color)
        color_default = this.getAgents[event.agent_id].color;
      return color_default;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
  },
};
</script>
