export default {
  agent: {
    id: "",
    name: "",
    photo: "",
  },
  agentSelected: null,
  agentAuthenticated: false,
  agents: null,
  sellerAgents: [],
  agentRoles: {
    advisor: { id: "advisor", name: "Asesor" },
    admin: { id: "admin", name: "Administrador" },
    super_admin: { id: "super_admin", name: "Super Administrador" },
  },
};
