import * as MutationsType from "./mutationsType";

const { eventsCollection } = require("@/config/firebase");

export default {
  async getAllEvents({ commit }) {
    let events = await eventsCollection.get();
    commit(MutationsType.SET_EVENTS, []);
    if (!events.empty) {
      events.forEach((event) => {
        commit(MutationsType.ADD_EVENT, { id: event.id, ...event.data() });
      });
    }
  },
};
