import * as MutationsType from "./mutationsType";

/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_CONTACT](state, payload) {
    state.contact = payload;
  },
  [MutationsType.SET_CONTACTS](state, payload) {
    state.contacts = payload;
  },
  [MutationsType.ADD_CONTACT](state, payload) {
    state.contacts.push(payload);
  },
  [MutationsType.SET_CONTACT_STATE](state, payload) {
    state.contact.state = payload;
  },
};
