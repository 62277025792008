import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_TICKET](state, payload) {
    state.ticket = payload;
  },
  [MutationsType.SET_TICKETS](state, payload) {
    state.tickets = payload;
  },
  [MutationsType.ADD_TICKET](state, payload) {
    state.tickets.push(payload);
  },
  [MutationsType.SET_TICKET_STATE](state, payload) {
    state.ticket.state = payload;
  },
  [MutationsType.SET_TICKET_ORIGINS](state, payload) {
    state.ticketOrigins = payload;
  },
  [MutationsType.ADD_TICKET_ORIGIN](state, payload) {
    state.ticketOrigins.push(payload);
  },
};
