<template>
  <v-app id="agents">
    <div v-if="getAgent && getAgentsToArray && getAgentRoles">
      <v-card class="mb-2 mt-4">
        <v-col cols="12" class="px-4 py-0">
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <v-text-field v-model="search" label="Buscar" single-line hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      <v-card>
        <v-col cols="12" class="pa-4">
          <v-data-table :headers="headers" :items="getAgentsToArray" :search="search" :items-per-page="30" sort-by>
            <template v-slot:item.role="{ item }">
              <span>{{ getAgentRoles[item.role] ? getAgentRoles[item.role].name : "Sin asignar" }}</span>
            </template>
            <template v-slot:item.operations="{ item }">
              <v-btn text icon :to="`/agent/${item.id}`">
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
            <v-alert v-slot:no-results :value="true" color="error" icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert
            >
          </v-data-table>
        </v-col>
      </v-card>
      <!--
      <v-btn to="/agent/register" fab dark fixed bottom right color="secondary">
        <v-icon size="16">fa-user-plus</v-icon>
      </v-btn>
      -->
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";

export default {
  name: "Agents",
  data() {
    return {
      agent: null,
      search: "",
      headers: config.agentTable,
      pagination: {
        rowsPerPage: 20,
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgentsToArray", "getAgentRoles"]),
  },
  async created() {
    await this.getAllAgents();
  },
  methods: {
    ...mapActions(["getAllAgents"]),
  },
};
</script>
<style>
.max-w180 {
  max-width: 180px;
}
.v-avatar {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}
.v-btn {
  text-transform: none;
}
</style>
