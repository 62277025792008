var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "contact_detail" } },
    [
      _vm.getAgent && _vm.getContact && _vm.getAgents
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "sticky-75" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.tabs,
                                callback: function($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs"
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                INFORMACIÓN\n                "
                                      ),
                                      !_vm.editInformation
                                        ? _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    size: "16",
                                                                    icon: "",
                                                                    small: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "16"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        _vm.editInformation = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-pencil-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    714668038
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Editar información")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3"
                                        },
                                        [
                                          _vm.editInformation
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("h4", [
                                                    _vm._v(
                                                      "Editar información del Contacto"
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editInformation
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("ContactForm", {
                                                    attrs: { action: "update" },
                                                    on: {
                                                      cancelInformation:
                                                        _vm.cancelInformation
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fill-height text-center"
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.contactFields,
                                                        function(
                                                          contactField,
                                                          i
                                                        ) {
                                                          return [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                key: i,
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .getContact[
                                                                          contactField
                                                                            .field
                                                                        ],
                                                                      label:
                                                                        contactField.label,
                                                                      readonly: !_vm.editInformation
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                ETIQUETAS\n                "
                                      ),
                                      !_vm.editTags
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "pa-2 mr-4",
                                              staticStyle: {
                                                "font-size": "16px",
                                                position: "absolute",
                                                "z-index": "10000",
                                                right: "35px"
                                              },
                                              attrs: { size: "16" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.editTags = true
                                                }
                                              }
                                            },
                                            [_vm._v("fas fa-pencil-alt")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-form",
                                        { attrs: { "lazy-validation": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "text-center px-3 pt-2"
                                            },
                                            [
                                              _vm.editTags
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          "Editar etiquetas del Contacto"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  [
                                                    _c("v-combobox", {
                                                      staticClass: "h-auto",
                                                      attrs: {
                                                        items:
                                                          _vm.getActiveTags,
                                                        disabled: !_vm.editTags,
                                                        chips: "",
                                                        label: "Etiquetas",
                                                        multiple: "",
                                                        "item-id": "name",
                                                        "item-text": "name",
                                                        "return-object": ""
                                                      },
                                                      on: {
                                                        change: _vm.changeTags
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var attrs =
                                                                ref.attrs
                                                              var item =
                                                                ref.item
                                                              var select =
                                                                ref.select
                                                              var selected =
                                                                ref.selected
                                                              return [
                                                                _c(
                                                                  "v-chip",
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        "input-value": selected,
                                                                        close:
                                                                          _vm.editTags,
                                                                        color:
                                                                          item.color
                                                                      },
                                                                      on: {
                                                                        click: select,
                                                                        "click:close": function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeTag(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.name
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " \n                              "
                                                                    ),
                                                                    _c("span")
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "no-data",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  El tag no existe. Presiona "
                                                                            ),
                                                                            _c(
                                                                              "kbd",
                                                                              [
                                                                                _vm._v(
                                                                                  "enter"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " para crearlo.\n                                "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2494687138
                                                      ),
                                                      model: {
                                                        value: _vm.tags,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.tags = $$v
                                                        },
                                                        expression: "tags"
                                                      }
                                                    })
                                                  ]
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c("TagModal"),
                                              _vm._v(" "),
                                              _vm.editTags
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            rounded: "",
                                                            color: "accent",
                                                            loading:
                                                              _vm.loadSaveTags
                                                          },
                                                          on: {
                                                            click: _vm.saveTags
                                                          }
                                                        },
                                                        [_vm._v("Guardar")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            rounded: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.cancelTags()
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Cancelar")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                VENTAS\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3 pt-2"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "7" }
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Total de dinero comprado:\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "font-weight-medium"
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatCurrency(
                                                          _vm.getContact
                                                            .total_value || 0
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-1 text-right",
                                              attrs: { cols: "7" }
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Total de transacciones:\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-1",
                                              attrs: { cols: "5" }
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "font-weight-medium"
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getContact
                                                          .total_sales || 0
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                ASISTENCIA\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3 pt-2"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _vm.getContact.agent_id
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "50px",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm.getAgents[
                                                            _vm.getContact
                                                              .agent_id
                                                          ].photo
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm
                                                                      .getAgents[
                                                                      _vm
                                                                        .getContact
                                                                        .agent_id
                                                                    ].photo,
                                                                  alt: "Avatar"
                                                                }
                                                              })
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "white--text headline"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getAgents[
                                                                          _vm
                                                                            .getContact
                                                                            .agent_id
                                                                        ].full_name.substring(
                                                                          0,
                                                                          1
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "50px",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text headline"
                                                            },
                                                            [_vm._v("-")]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                              _vm._v(" "),
                                              _vm.getAgent.role ===
                                              _vm.$roles.ADVISOR
                                                ? _c("v-text-field", {
                                                    staticClass: "pl-2",
                                                    attrs: {
                                                      label: "Encargado",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.getAgent.full_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.getAgent,
                                                          "full_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "getAgent.full_name"
                                                    }
                                                  })
                                                : _c("v-select", {
                                                    staticClass: "pl-2 w95",
                                                    attrs: {
                                                      items:
                                                        _vm.getAgentsToArray,
                                                      "item-text": "full_name",
                                                      "item-value": "id",
                                                      value:
                                                        _vm.getContact.agent_id,
                                                      label: "Encargado",
                                                      placeholder: "Sin asignar"
                                                    },
                                                    model: {
                                                      value: _vm.agentContact,
                                                      callback: function($$v) {
                                                        _vm.agentContact = $$v
                                                      },
                                                      expression: "agentContact"
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                FUENTE\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3 pt-2"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "size",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                      Obtenido desde "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getContact.origin
                                                    )
                                                  )
                                                ])
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.getContact.created_uid
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "d-flex",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "50px",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm.getAgents[
                                                            _vm.getContact
                                                              .created_uid
                                                          ].photo
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm
                                                                      .getAgents[
                                                                      _vm
                                                                        .getContact
                                                                        .created_uid
                                                                    ].photo,
                                                                  alt: "Avatar"
                                                                }
                                                              })
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "white--text headline"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getAgents[
                                                                          _vm
                                                                            .getContact
                                                                            .created_uid
                                                                        ].full_name.substring(
                                                                          0,
                                                                          1
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-2 w95",
                                                    attrs: {
                                                      value:
                                                        _vm.getAgents[
                                                          _vm.getContact
                                                            .created_uid
                                                        ].full_name,
                                                      label: "Registrado por:",
                                                      readonly: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                PRIVACIDAD DE DATOS\n                "
                                      ),
                                      !_vm.editPrivacy
                                        ? _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    size: "16",
                                                                    icon: "",
                                                                    small: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "16"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        _vm.editPrivacy = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-pencil-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3341447052
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Editar privacidad de datos"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "px-3 pt-2" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  readonly: !_vm.editPrivacy
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function() {
                                                        return [
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                          Autorización para utilizar sus datos en cualquier campaña/actividad.\n                        "
                                                            )
                                                          ])
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1484814069
                                                ),
                                                model: {
                                                  value: _vm.terms,
                                                  callback: function($$v) {
                                                    _vm.terms = $$v
                                                  },
                                                  expression: "terms"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.editPrivacy
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        rounded: "",
                                                        color: "accent",
                                                        loading:
                                                          _vm.loadSavePrivacy
                                                      },
                                                      on: {
                                                        click: _vm.savePrivacy
                                                      }
                                                    },
                                                    [_vm._v("Guardar")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        rounded: ""
                                                      },
                                                      on: {
                                                        click: _vm.cancelPrivacy
                                                      }
                                                    },
                                                    [_vm._v("Cancelar")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-2 font-weight-bold pa-4",
                                  attrs: { cols: "12" }
                                },
                                [_vm._v("HISTORIAL DE SEGUIMIENTO")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12 pa-4" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formActivity",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12 pa-4" } },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  name: "input",
                                                  label:
                                                    "Escribe tu comentario",
                                                  "auto-grow": "",
                                                  rows: "1",
                                                  placeholder: " ",
                                                  rules: _vm.rules.required
                                                },
                                                model: {
                                                  value: _vm.comment.text,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.comment,
                                                      "text",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "comment.text"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.comment.type === 4
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0 px-4",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "4"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Nombre del evento",
                                                              placeholder: " ",
                                                              rules:
                                                                _vm.rules
                                                                  .required
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.comment
                                                                  .event_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.comment,
                                                                  "event_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "comment.event_name"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "4"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "close-on-content-click": false,
                                                                "nudge-right": 40,
                                                                transition:
                                                                  "scale-transition",
                                                                "offset-y": "",
                                                                "min-width":
                                                                  "300px"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "Fecha del evento",
                                                                                placeholder:
                                                                                  "Seleccione",
                                                                                "prepend-icon":
                                                                                  "fa fa-calendar",
                                                                                readonly:
                                                                                  "",
                                                                                rules:
                                                                                  _vm
                                                                                    .rules
                                                                                    .required
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .comment
                                                                                    .event_date,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.comment,
                                                                                    "event_date",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "comment.event_date"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                2132987457
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.menu_comment_date,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.menu_comment_date = $$v
                                                                },
                                                                expression:
                                                                  "menu_comment_date"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-date-picker",
                                                                {
                                                                  attrs: {
                                                                    min: _vm
                                                                      .$moment()
                                                                      .format(
                                                                        "YYYY-MM-DD"
                                                                      )
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.menu_comment_date = false
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .comment
                                                                        .event_date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.comment,
                                                                        "event_date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "comment.event_date"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "4"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              ref: "dialog",
                                                              attrs: {
                                                                "return-value":
                                                                  _vm.comment
                                                                    .event_time,
                                                                persistent: "",
                                                                width: "290px"
                                                              },
                                                              on: {
                                                                "update:returnValue": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.comment,
                                                                    "event_time",
                                                                    $event
                                                                  )
                                                                },
                                                                "update:return-value": function(
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.comment,
                                                                    "event_time",
                                                                    $event
                                                                  )
                                                                }
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-text-field",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                label:
                                                                                  "Hora del evento",
                                                                                placeholder:
                                                                                  "Seleccione",
                                                                                "prepend-icon":
                                                                                  "fas fa-clock",
                                                                                readonly:
                                                                                  "",
                                                                                rules:
                                                                                  _vm
                                                                                    .rules
                                                                                    .required
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .comment
                                                                                    .event_time,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.comment,
                                                                                    "event_time",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "comment.event_time"
                                                                              }
                                                                            },
                                                                            on
                                                                          )
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                4269061108
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.dialog_comment_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.dialog_comment_time = $$v
                                                                },
                                                                expression:
                                                                  "dialog_comment_time"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _vm.dialog_comment_time
                                                                ? _c(
                                                                    "v-time-picker",
                                                                    {
                                                                      attrs: {
                                                                        "full-width":
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .comment
                                                                            .event_time,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.comment,
                                                                            "event_time",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "comment.event_time"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            text:
                                                                              "",
                                                                            color:
                                                                              "primary"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.dialog_comment_time = false
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cancelar"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            text:
                                                                              "",
                                                                            color:
                                                                              "primary"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.$refs.dialog.save(
                                                                                _vm
                                                                                  .comment
                                                                                  .event_time
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Aceptar"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticStyle: {
                                                    height: "30px"
                                                  },
                                                  attrs: { row: "" },
                                                  model: {
                                                    value: _vm.comment.type,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.comment,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "comment.type"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pr-1",
                                                      attrs: { size: "18" }
                                                    },
                                                    [_vm._v("fas fa-comments")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "grey darken-1",
                                                      value: 0,
                                                      selected: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pr-1",
                                                      attrs: { size: "18" }
                                                    },
                                                    [_vm._v("fas fa-phone")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "grey darken-1",
                                                      value: 1
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pr-1",
                                                      attrs: { size: "18" }
                                                    },
                                                    [_vm._v("fas fa-envelope")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "grey darken-1",
                                                      value: 2
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pr-1",
                                                      attrs: { size: "18" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "far fa-calendar-alt"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      color: "grey darken-1",
                                                      value: 4
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "12", md: "6" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mt-0",
                                                  attrs: {
                                                    color: "primary",
                                                    rounded: ""
                                                  },
                                                  on: {
                                                    click: _vm.commentTimeline
                                                  }
                                                },
                                                [_vm._v("Comentar")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          [
                            _c(
                              "v-card",
                              { staticClass: "mx-auto" },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "py-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "10",
                                          "offset-sm": "1"
                                        }
                                      },
                                      [
                                        _vm.getTimeline &&
                                        _vm.getTimeline.length > 0
                                          ? _c(
                                              "v-timeline",
                                              {
                                                attrs: {
                                                  "align-top": "",
                                                  dense: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-slide-x-reverse-transition",
                                                  {
                                                    attrs: {
                                                      group: "",
                                                      "hide-on-leave": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.getTimeline,
                                                      function(c, index) {
                                                        return [
                                                          _c(
                                                            "v-timeline-item",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                icon:
                                                                  _vm
                                                                    .iconTypeComment[
                                                                    c.type
                                                                  ],
                                                                color:
                                                                  "primary",
                                                                large: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "px-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "pa-2"
                                                                    },
                                                                    [
                                                                      c.created_at
                                                                        ? _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "grey--text text--darken-1",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$formatTimestamp(
                                                                                      c.created_at,
                                                                                      true
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "font-italic pb-2",
                                                                          attrs: {
                                                                            cols:
                                                                              "12",
                                                                            sm:
                                                                              "8",
                                                                            "offset-sm":
                                                                              "2"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            '\n                                "'
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  c.text_formatted ||
                                                                                    c.text
                                                                                )
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            '"\n                              '
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      c.order_id
                                                                        ? _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "pb-2",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    to:
                                                                                      "/sale/" +
                                                                                      c.order_id,
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      "accent",
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pr-2",
                                                                                      attrs: {
                                                                                        dark:
                                                                                          "",
                                                                                        small:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fas fa-external-link-alt"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                  Detalle de compra\n                                "
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      c.ticket_id
                                                                        ? _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "pb-2",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    to:
                                                                                      "/ticket/" +
                                                                                      c.ticket_id,
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      "accent",
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pr-2",
                                                                                      attrs: {
                                                                                        dark:
                                                                                          "",
                                                                                        small:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fas fa-external-link-alt"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                  Detalle del ticket\n                                "
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm
                                                                        .getAgents[
                                                                        c.uid
                                                                      ]
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex pa-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "v-avatar",
                                                                                    {
                                                                                      attrs: {
                                                                                        size:
                                                                                          "25px",
                                                                                        color:
                                                                                          "black"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm
                                                                                        .getAgents[
                                                                                        c
                                                                                          .uid
                                                                                      ]
                                                                                        .photo
                                                                                        ? _c(
                                                                                            "img",
                                                                                            {
                                                                                              attrs: {
                                                                                                src:
                                                                                                  _vm
                                                                                                    .getAgents[
                                                                                                    c
                                                                                                      .uid
                                                                                                  ]
                                                                                                    .photo,
                                                                                                alt:
                                                                                                  "Avatar"
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "white--text headline"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    _vm.getAgents[
                                                                                                      c
                                                                                                        .uid
                                                                                                    ].full_name.substring(
                                                                                                      0,
                                                                                                      1
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "h6",
                                                                                {
                                                                                  staticClass:
                                                                                    "pt-1 pl-2 caption grey--text text--darken-2"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .getAgents[
                                                                                            c
                                                                                              .uid
                                                                                          ]
                                                                                            .name
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  c.type ==
                                                                                  1
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "realizó la llamada."
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : c.type ==
                                                                                      2
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "envió el correo."
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : c.type ==
                                                                                      4
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "agendó un evento para el " +
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .$moment(
                                                                                                    c.date
                                                                                                  )
                                                                                                  .format(
                                                                                                    "DD-MM-YY"
                                                                                                  )
                                                                                              )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "agregó la nota."
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "text-center py-4"
                                              },
                                              [_vm._v("Aún no hay comentarios")]
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }