/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/Login";
import Sales from "@/views/sales/Sales";
import SaleDetail from "@/views/sales/options/Detail";
import ImporterSales from "@/views/sales/options/Importer";
import Contacts from "@/views/contacts/Contacts";
import ImporterContacts from "@/views/contacts/options/Importer";
import ImporterContactActivities from "@/views/contacts/options/ImporterActivities";
import ContactDetail from "@/views/contacts/options/Detail";
import ContactRegister from "@/views/contacts/options/Register";
import Tickets from "@/views/tickets/Tickets";
import TicketDetail from "@/views/tickets/options/Detail";
import TicketRegister from "@/views/tickets/options/Register";
import ImporterTickets from "@/views/tickets/options/Importer";
import ImporterTicketActivities from "@/views/tickets/options/ImporterActivities";
import Agents from "@/views/agents/Agents";
import AgentDetail from "@/views/agents/options/Detail";
import Events from "@/views/events/Events";
import Statistics from "@/views/statistics/Statistics";
import Settings from "@/views/settings/Settings";

import getRole from "@/helpers/get_role";

const { auth, contactsCollection, ticketsCollection } = require("@/config/firebase");

Vue.use(VueRouter);

async function getAgentRole() {
  let agent = auth.currentUser;
  let role = await getRole(agent);
  return role;
}

async function verifyPermissions(role, roles) {
  return roles.find((r) => {
    return r === role;
  });
}

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/tickets",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        authorizedRoles: ["anonymous"],
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: Contacts,
      props: (route) => ({ state: route.query.state, country: route.query.country, agent: route.query.agent }),
      meta: {
        title: "Contactos",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/contacts/importer",
      name: "importer_contacts",
      component: ImporterContacts,
      meta: {
        title: "Contactos",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/contacts/importer-activities",
      name: "importer_contact_activities",
      component: ImporterContactActivities,
      meta: {
        title: "Contactos",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/contact/register",
      name: "contact_register",
      component: ContactRegister,
      meta: {
        title: "Contactos",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/contact/:id",
      name: "contact_detail",
      component: ContactDetail,
      meta: {
        title: "Contactos",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
      async beforeEnter(to, from, next) {
        let response = await contactsCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            verifyPermissions(to.meta.role, ["super_admin", "admin"]) ||
            response.data().agent_id === auth.currentUser.uid
          ) {
            next();
          } else {
            next("/contacts");
          }
        } else {
          next("/contacts");
        }
      },
    },
    {
      path: "/sales",
      name: "sales",
      component: Sales,
      meta: {
        title: "Ventas",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/sales/importer",
      name: "importer_sales",
      component: ImporterSales,
      meta: {
        title: "Ventas",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/sale/:id",
      name: "sale_detail",
      component: SaleDetail,
      meta: {
        title: "Ventas",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      component: Tickets,
      meta: {
        title: "Tickets",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/ticket/register",
      name: "ticket_register",
      component: TicketRegister,
      meta: {
        title: "Tickets",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/ticket/:id",
      name: "ticket_detail",
      component: TicketDetail,
      meta: {
        title: "Tickets",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
      async beforeEnter(to, from, next) {
        let response = await ticketsCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            verifyPermissions(to.meta.role, ["super_admin", "admin"]) ||
            response.data().agent_id === auth.currentUser.uid
          ) {
            next();
          } else {
            next("/tickets");
          }
        } else {
          next("/tickets");
        }
      },
    },
    {
      path: "/tickets/importer",
      name: "importer_tickets",
      component: ImporterTickets,
      meta: {
        title: "Tickets",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/tickets/importer-activities",
      name: "importer_ticket_activities",
      component: ImporterTicketActivities,
      meta: {
        title: "Tickets",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/agents",
      name: "agents",
      component: Agents,
      meta: {
        title: "Trabajadores",
        authorizedRoles: ["admin", "super_admin"],
      },
    },
    {
      path: "/agent/:id",
      name: "agent_detail",
      component: AgentDetail,
      meta: {
        title: "Trabajadores",
        authorizedRoles: ["admin", "super_admin"],
      },
    },
    {
      path: "/events",
      name: "events",
      component: Events,
      meta: {
        title: "Eventos",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "/statistics",
      name: "statistics",
      component: Statistics,
      meta: {
        title: "Estadísticas",
        authorizedRoles: ["admin", "super_admin"],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        title: "Configuraciones",
        authorizedRoles: ["advisor", "admin", "super_admin"],
      },
    },
    {
      path: "*",
      redirect: "/login",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let authorizedRoles = to.meta.authorizedRoles;
  let role = await getAgentRole();
  if (authorizedRoles) {
    let authorized = await verifyPermissions(role, authorizedRoles);
    if (!authorized) {
      if (role === "anonymous") {
        next("/login");
      } else {
        next("/");
      }
    } else {
      to.meta.role = role;
      next();
    }
  }
});

export default router;
