import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { roles } from "../../../helpers/get_constants";
import getRole from "../../../helpers/get_role";

const { ticketsCollection, auth } = require("@/config/firebase");

export default {
  // eslint-disable-next-line
  async createTicket({ commit }, payload) {
    try {
      let agent = auth.currentUser;
      let response = await requestAxios({
        url: "/awy/api/v1/ticket",
        method: "POST",
        data: {
          origin: "agent",
          created_uid: agent.uid,
          ...payload.ticket,
        },
      });
      return response.data;
    } catch (error) {
      console.error("createTicket: ", error);
      return error.response.data;
    }
  },
  async getTicketById({ commit }, payload) {
    try {
      await ticketsCollection.doc(payload.ticket_id).onSnapshot(async (doc) => {
        commit(MutationsType.SET_TICKET, { id: doc.id, ...doc.data() });
      });
    } catch (error) {
      console.error("getTicketById: ", error);
    }
  },
  async getAllTickets({ commit }) {
    let agent = auth.currentUser;
    let agent_role = await getRole(agent);
    let is_advisor = roles.ADVISOR === agent_role;

    let reference = is_advisor ? ticketsCollection.where("agent_id", "==", agent.uid) : ticketsCollection;
    await reference.orderBy("created_at", "desc").onSnapshot(async (snap) => {
      let data;
      commit(MutationsType.SET_TICKETS, []);
      commit(MutationsType.SET_TICKET_ORIGINS, []);
      snap.docs.forEach((doc) => {
        data = doc.data();
        commit(MutationsType.ADD_TICKET, { id: doc.id, ...data });
        if (data.origin) commit(MutationsType.ADD_TICKET_ORIGIN, data.origin);
      });
    });
  },
  async setTicketState({ commit }, payload) {
    try {
      await ticketsCollection.doc(payload.ticket_id).update({
        state: payload.state,
        state_reason: payload.state_reason || "",
      });
      commit(MutationsType.SET_TICKET_STATE, payload.state);
    } catch (error) {
      console.error("setTicketState: ", error);
    }
  },
  // eslint-disable-next-line
  async setTicketAgent({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/assign-agent",
        method: "POST",
        data: {
          ticket_id: payload.ticket_id,
          agent_id: payload.agent_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error("setTicketAgent: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async updateTicket({ commit }, payload) {
    try {
      let user = await auth.currentUser;
      let response = await requestAxios({
        url: `/awy/api/v1/ticket/${payload.ticket_id}`,
        method: "PATCH",
        data: {
          ...payload.data,
          agent_id: user.uid,
        },
      });
      return response.data;
    } catch (error) {
      console.error("updateTicket: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async downloadTickets({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/download",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("downloadTickets: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportTicketTemplate({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/export-template",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("exportTicketTemplate: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importTickets({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/import",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("importTickets: ", error);
      throw error.response.data;
    }
  },
};
