async function getRole(agent) {
  let rol = "anonymous";
  if (agent) {
    let token = await agent.getIdTokenResult();
    rol = token.claims && token.claims.role ? token.claims.role : "advisor";
  }
  return rol;
}

export default getRole;
