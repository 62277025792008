<template>
  <v-app id="ticket_register">
    <v-container>
      <v-row>
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="ml-2">Nuevo ticket</h2>
          <TicketForm />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import TicketForm from "@/components/forms/TicketForm";

export default {
  name: "ContactRegister",
  components: {
    TicketForm,
  },
};
</script>
<style>
.v-text-field__details,
.v-messages.theme--light {
  display: block !important;
}
.v-input {
  height: 52px;
}
</style>
