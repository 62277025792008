var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "calendar" } },
    [
      _vm.getAgents
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "10",
                        md: "8",
                        "offset-sm": "1",
                        "offset-md": "2"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h2", [_vm._v("Calendario de eventos")])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-sheet",
                                { attrs: { height: "64" } },
                                [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { flat: "", color: "white" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            outlined: "",
                                            color: "primary"
                                          },
                                          on: { click: _vm.setToday }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Hoy\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            text: "",
                                            small: ""
                                          },
                                          on: { click: _vm.prev }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fas fa-chevron-left")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            fab: "",
                                            text: "",
                                            small: ""
                                          },
                                          on: { click: _vm.next }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fas fa-chevron-right")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-toolbar-title", [
                                        _vm._v(_vm._s(_vm.title))
                                      ]),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "pr-2" }, [
                                        _vm._v("Ver: ")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { bottom: "", right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            color: "primary"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.typeToLabel[
                                                                _vm.type
                                                              ]
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              right: "",
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fas fa-chevron-down"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2601611840
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.type = "day"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v("Día")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.type = "week"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v("Semana")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      _vm.type = "month"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v("Mes")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.getEvents
                                ? _c(
                                    "v-sheet",
                                    { attrs: { height: "600" } },
                                    [
                                      _c("v-calendar", {
                                        ref: "calendar",
                                        attrs: {
                                          color: "primary",
                                          events: _vm.getEvents,
                                          "event-color": _vm.getEventColor,
                                          "event-margin-bottom": 3,
                                          now: _vm.today,
                                          type: _vm.type,
                                          locale: "es"
                                        },
                                        on: {
                                          "click:event": _vm.showEvent,
                                          "click:more": _vm.viewDay,
                                          "click:date": _vm.viewDay,
                                          change: _vm.updateRange
                                        },
                                        model: {
                                          value: _vm.focus,
                                          callback: function($$v) {
                                            _vm.focus = $$v
                                          },
                                          expression: "focus"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": false,
                                            activator: _vm.selectedElement,
                                            "full-width": "",
                                            "offset-x": "",
                                            "offset-y": "",
                                            "max-width": "300px"
                                          },
                                          model: {
                                            value: _vm.selectedOpen,
                                            callback: function($$v) {
                                              _vm.selectedOpen = $$v
                                            },
                                            expression: "selectedOpen"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                color: "grey lighten-4",
                                                flat: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  attrs: {
                                                    color: _vm.getEventColor(
                                                      _vm.selectedEvent
                                                    ),
                                                    dark: ""
                                                  }
                                                },
                                                [
                                                  _c("v-toolbar-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedEvent.name
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.selectedOpen = false
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("fas fa-times")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-3",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 mb-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedEvent
                                                                    .details
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.selectedEvent
                                                        .ticket_id
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "text-left pb-4",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    small: "",
                                                                    to:
                                                                      "/ticket/" +
                                                                      _vm
                                                                        .selectedEvent
                                                                        .ticket_id,
                                                                    target:
                                                                      "_blank",
                                                                    color:
                                                                      "primary"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          Ver detalle del ticket\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getAgents[
                                                        _vm.selectedEvent
                                                          .agent_id
                                                      ]
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "d-flex py-0 my-2",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-avatar",
                                                                {
                                                                  attrs: {
                                                                    size:
                                                                      "40px",
                                                                    color:
                                                                      "black"
                                                                  }
                                                                },
                                                                [
                                                                  _vm.getAgents[
                                                                    _vm
                                                                      .selectedEvent
                                                                      .agent_id
                                                                  ].photo
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src:
                                                                              _vm
                                                                                .getAgents[
                                                                                _vm
                                                                                  .selectedEvent
                                                                                  .agent_id
                                                                              ]
                                                                                .photo,
                                                                            alt:
                                                                              "Avatar"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "white--text headline"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.getAgents[
                                                                                  _vm
                                                                                    .selectedEvent
                                                                                    .agent_id
                                                                                ].full_name.substring(
                                                                                  0,
                                                                                  1
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "caption font-weight-bold"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Encargado"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "h5",
                                                                    {
                                                                      staticClass:
                                                                        "body-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .getAgents[
                                                                            _vm
                                                                              .selectedEvent
                                                                              .agent_id
                                                                          ]
                                                                            .full_name
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "6"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                to:
                                                                  "/contact/" +
                                                                  _vm
                                                                    .selectedEvent
                                                                    .contact_id,
                                                                target:
                                                                  "_blank",
                                                                outlined: "",
                                                                color: "primary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Ver contacto\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }