<template>
  <v-app id="agent_detail">
    <v-row v-if="agent && role && getAgentSelected && getAgentRolesToArray">
      <!-- Agent information -->
      <v-col cols="12" md="5">
        <v-row class="sticky-75">
          <v-col cols="12">
            <v-expansion-panels v-model="tabs" multiple>
              <!-- Información -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  INFORMACIÓN
                  <!--
                  <div v-if="role === $roles.SUPER_ADMIN && !editInformation" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-4" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editInformation = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar información</span>
                    </v-tooltip>
                  </div>
                  -->
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3">
                    <v-col v-if="editInformation" cols="12">
                      <h4>Editar información del trabajado</h4>
                    </v-col>
                    <v-col v-if="editInformation" cols="12">
                      <ContactForm action="update" @cancelInformation="cancelInformation" />
                    </v-col>
                    <v-col v-else cols="12">
                      <v-row class="fill-height text-center">
                        <template v-for="(agentField, i) in agentFields">
                          <v-col :key="i" cols="12">
                            <v-text-field
                              :value="getAgentSelected[agentField.field]"
                              :label="agentField.label"
                              :readonly="!editInformation"
                            ></v-text-field>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Rol -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ROL
                  <v-icon
                    v-if="role === $roles.SUPER_ADMIN && !editRole && agent.uid !== getAgentSelected.uid"
                    size="16"
                    class="pa-2 mr-4"
                    style="font-size: 16px;position: absolute;z-index: 10000;right: 35px;"
                    @click.stop.prevent="editRole = true"
                    >fas fa-pencil-alt</v-icon
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="formRole" lazy-validation>
                    <v-row class="fill-height text-center px-3 pt-2">
                      <v-col v-if="editRole" cols="12">
                        <h4>Editar rol del Trabajador</h4>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="agent_role"
                          :value="getAgentSelected.role"
                          :items="getAgentRolesToArray"
                          :readonly="!editRole"
                          style="font-size: 15px;"
                          item-text="name"
                          item-value="id"
                          label="Estado"
                          class="pt-2"
                        ></v-select>
                      </v-col>
                      <v-col v-if="editRole" cols="12">
                        <v-btn small rounded color="accent" @click="saveRole">Guardar</v-btn>
                        <v-btn small rounded @click="cancelRole">Cancelar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";
import { auth } from "@/config/firebase";
import getRole from "@/helpers/get_role";

export default {
  name: "AgentDetail",
  data() {
    return {
      agent: null,
      agent_id: this.$route.params.id,
      tabs: [0, 1],
      editRole: false,
      editInformation: false,
      role: null,
      agent_role: "",
      agentFields: [],
    };
  },
  computed: {
    ...mapGetters(["getAgentSelected", "getAgentRolesToArray"]),
  },
  watch: {
    getAgentSelected(value) {
      if (value) this.agent_role = value.role;
    },
  },
  async mounted() {
    this.agent = await auth.currentUser;
    this.role = await getRole(this.agent);
    await this.getAgentById({ agent: { uid: this.agent_id }, is_selected: true });
    this.getAgentFields();
  },
  methods: {
    ...mapActions(["getAgentById", "setAgentRole"]),
    getAgentFields() {
      let schema = config.agentFormSchema;
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          this.agentFields.push({ field: field, ...schema[field] });
        }
      }
    },
    saveRole() {
      if (this.$refs.formRole.validate()) {
        this.editRole = false;
        this.setAgentRole({
          agent_id: this.agent_id,
          agent_role: this.agent_role,
        });
      }
    },
    cancelRole() {
      this.editRole = false;
      this.agent_role = this.getAgentSelected.role;
    },
  },
};
</script>
<style>
.v-card__title .v-toolbar__content {
  padding: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
}
.sticky-75 {
  position: sticky;
  top: 75px;
  z-index: 1;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-bottom: 0;
}
.v-messages.theme--light {
  display: none;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.h-auto {
  height: auto !important;
}
.v-toolbar-info .v-toolbar__content {
  padding: 0 !important;
  height: 58px !important;
}
</style>
