<template>
  <v-app id="contacts">
    <div v-if="getAgent && getAgents && getContacts">
      <v-card class="mb-2 mt-4">
        <v-progress-linear
          :active="loadExport"
          :indeterminate="loadExport"
          absolute
          top
          color="secondary accent-4"
        ></v-progress-linear>
        <v-col cols="12" class="px-4 py-0">
          <v-row v-show="showFilters">
            <v-col cols="12" sm="4" md="3">
              <v-text-field v-model="search" label="Buscar contacto" single-line hide-details></v-text-field>
              <div class="my-auto hidden-md-and-up">
                <v-btn icon class="mr-0" @click="showFilters = !showFilters">
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="2">
              <v-select
                v-model="countrySelected"
                :items="countryArray"
                menu-props="auto"
                item-text="name"
                item-value="id"
                label="País"
                class="px-1"
                return-object
                @change="addParamToRoute('country', countrySelected)"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                v-if="getAgent.role === $roles.ADVISOR"
                v-model="getAgent.full_name"
                label="Encargado"
                readonly
              >
              </v-text-field>
              <v-select
                v-else
                v-model="agentSelected"
                :items="agentsArray"
                item-text="full_name"
                item-value="uid"
                label="Encargado"
                class="px-1"
                @change="addParamToRoute('agent', agentSelected)"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="2" class="text-center d-flex">
              <v-btn :loading="loadExport" outlined color="primary" dark class="mt-2" @click="exportData">
                Descargar
                <v-icon right size="16">fa-file-download</v-icon>
              </v-btn>
              <v-menu offset-y bottom left class="ml-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="my-auto" v-on="on">
                    <v-icon small>fa fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item to="/contacts/importer">
                    <v-list-item-title>Importar contactos</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/contacts/importer-activities">
                    <v-list-item-title>Importar actividades</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      <v-card class="mb-5 pb-5">
        <v-col cols="12" class="pa-4">
          <v-data-table :headers="headers" :items="filterContacts" :search="search" :items-per-page="30" sort-by>
            <template v-slot:item.created_at="{ item }">
              {{ $formatTimestamp(item.created_at) }}
            </template>
            <template v-slot:item.agent_id="{ item }">
              {{ item.agent_id ? getAgents[item.agent_id].name : "Sin asignar" }}
            </template>
            <template v-slot:item.operations="{ item }">
              <v-btn text icon :to="`/contact/${item.id}`">
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
            <v-alert v-slot:no-results :value="true" color="error" icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert
            >
          </v-data-table>
        </v-col>
      </v-card>
      <v-btn to="/contact/register" fab dark fixed bottom right color="secondary">
        <v-icon size="16">fa-user-plus</v-icon>
      </v-btn>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getCountryList } from "@/helpers";
import config from "Config/config";
import { auth } from "@/config/firebase";

export default {
  name: "Contacts",
  data() {
    return {
      agent: null,
      search: "",
      countryArray: [],
      agentsArray: [],
      agentSelected: this.$route.query.agent ? this.$route.query.agent : 0,
      countrySelected: this.$route.query.country ? this.$route.query.country : 0,
      showFilters: true,
      headers: config.tableContact,
      pagination: {
        rowsPerPage: 20,
      },
      loadExport: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgents", "getAgentsToArray", "getContacts"]),
    filterContacts() {
      let data = this.getContacts;
      if (this.agentSelected !== 0) {
        data = data.filter((contact) => {
          return contact.agent_id == this.agentSelected;
        });
      }
      if (this.countrySelected && this.countrySelected.id !== 0) {
        data = data.filter((contact) => {
          return contact.country && contact.country == this.countrySelected.name;
        });
      }
      return data;
    },
  },
  watch: {
    getAgents(value) {
      if (value) this.setAgentsToSelect();
    },
  },
  async created() {
    await this.getAgentById({ agent: auth.currentUser });
    this.countryArray = await getCountryList(true);
    this.getAllContacts();
    this.getAllAgents();
  },
  methods: {
    ...mapActions(["getAgentById", "getAllAgents", "getAllContacts", "findAgent", "downloadContacts"]),
    async exportData() {
      try {
        this.loadExport = true;
        let response = await this.downloadContacts();
        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `awy-contacts-${this.$moment().format("DD-MM-YYYY-hh-mm")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadExport = false;
      }
    },
    setAgentsToSelect() {
      this.agentsArray = [
        { uid: 0, full_name: "Todos" },
        { uid: null, full_name: "Sin asignar" },
        ...this.getAgentsToArray,
      ];
    },
    addParamToRoute(param, value) {
      this.$route.query[param] = value;
    },
  },
};
</script>
<style>
.max-w180 {
  max-width: 180px;
}
.v-avatar {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}
.v-btn {
  text-transform: none;
}
</style>
