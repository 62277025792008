import { notification_types } from "@/helpers/get_constants";

function formatNotification(data) {
  let notification = {};

  switch (data.notification_type) {
    case notification_types.ASSIGN_TICKETS:
      if (data.importer_by) {
        notification.text = `Se te han asignado a ${data.cant_tickets} tickets nuevos :)`;
        notification.link = location.pathname === "/tickets" ? "" : "/tickets";
      } else {
        notification.text = "Has sido asignado a un ticket nuevo :)";
        notification.link = location.pathname === `/ticket/${data.ticket_id}` ? "" : `/ticket/${data.ticket_id}`;
      }
      break;
  }

  return notification;
}

export default formatNotification;
