import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { auth, agentsCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAgentById({ commit }, payload) {
    if (payload.agent) {
      let doc = await agentsCollection.doc(payload.agent.uid).get();
      let data = doc.data();
      data.role = data.role || "advisor";
      data.uid = doc.id;
      commit(payload.is_selected ? MutationsType.SET_AGENT_SELECTED : MutationsType.SET_AGENT, data);
    }
  },
  // eslint-disable-next-line
  async login({ commit }, payload) {
    try {
      let user = await auth.signInWithEmailAndPassword(payload.user.email, payload.user.password);
      return { data: user };
    } catch (error) {
      return { error: error };
    }
  },
  // eslint-disable-next-line
  async recoveryPassword({ commit }, payload) {
    try {
      await auth.sendPasswordResetEmail(payload.email);
      return true;
    } catch (error) {
      return { error: error };
    }
  },
  async logout({ commit }) {
    try {
      await auth.signOut();
      commit(MutationsType.SET_AGENT, null);
    } catch (error) {
      console.error(error);
    }
  },
  async getAllAgents({ commit }) {
    try {
      let agents = {};
      let sellerAgents = [];
      const querySnapshot = await agentsCollection.get();
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        if (!data.system_agent) {
          const _d = { id: doc.id, ...data };
          if (data.role === roles.ADVISOR) {
            sellerAgents.push(_d);
          }
          agents[doc.id] = _d;
        }
      });
      commit(MutationsType.SET_AGENTS, agents);
      commit(MutationsType.SET_SELLER_AGENTS, sellerAgents);
    } catch (error) {
      console.error(error);
    }
  },
  async findAgent({ commit }, uid) {
    try {
      const snap = await agentsCollection.doc(uid).get();
      if (!snap.exists) throw new Error("agent not found");
      commit(MutationsType.SET_AGENT, { uid: uid, ...snap.data() });
    } catch (error) {
      console.error(error);
    }
  },
  setAgent({ commit }, agent) {
    commit(MutationsType.SET_AGENT, agent);
  },
  setAgentAuthenticated({ commit }, agent) {
    commit(MutationsType.SET_AGENT_AUTHENTICATED, agent);
  },
  // eslint-disable-next-line
  async setAgentRole({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/agent/set-permissions",
        method: "POST",
        data: {
          agent_id: payload.agent_id,
          agent_role: payload.agent_role,
        },
      });
      return response.data;
    } catch (error) {
      console.error("agent/setAgentRole: ", error);
      return error.response.data;
    }
  },
};
