var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ticket_form" } },
    [
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "12" } },
        [
          _vm.ticket
            ? _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-ticket", "lazy-validation": "" }
                },
                [
                  _c("VFormBase", {
                    attrs: { value: _vm.ticket, schema: _vm.schema }
                  }),
                  _vm._v(" "),
                  _vm.action === "create"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", loading: _vm.loading },
                              on: { click: _vm.create }
                            },
                            [_vm._v("Guardar ticket")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                rounded: "",
                                color: "accent",
                                loading: _vm.loading
                              },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Guardar")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", rounded: "" },
                              on: { click: _vm.cancelUpdate }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }