export default {
  getAgent(state) {
    return state.agent;
  },
  getAgents(state) {
    return state.agents;
  },
  getAgentsToArray(state) {
    let agent, agents;
    if (state.agents) {
      agents = [];
      for (let a in state.agents) {
        agent = state.agents[a];
        if (agent.active) agents.push({ uid: a, ...agent });
      }
    }
    return agents;
  },
  getAgentRoles(state) {
    return state.agentRoles;
  },
  getAgentRolesToArray(state) {
    let roles;
    if (state.agentRoles) {
      roles = [];
      for (let r in state.agentRoles) {
        roles.push(state.agentRoles[r]);
      }
    }
    return roles;
  },
  getAgentSelected(state) {
    return state.agentSelected;
  },
};
