<template>
  <v-app id="contact_register">
    <v-container>
      <v-row>
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="ml-2">Nuevo contacto</h2>
          <ContactForm />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import ContactForm from "@/components/forms/ContactForm";

export default {
  name: "ContactRegister",
  components: {
    ContactForm,
  },
};
</script>
<style>
.v-text-field__details,
.v-messages.theme--light {
  display: block !important;
}
.v-input {
  height: 52px;
}
</style>
