var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            fixed: "",
            "clipped-left": _vm.clipped,
            dark: "",
            color: "primary"
          }
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "hidden-md-and-up",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.show($event)
              }
            }
          }),
          _vm._v(" "),
          _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }