<template>
  <div class="d-flex flex-nowrap pa-0" style="overflow-x: scroll">
    <div v-drag-and-drop:options="options" class="col-12 pa-0">
      <ul class="drag-list col-12 pa-0 pb-4 flex-nowrap fill-height">
        <li v-for="group in groups" :key="group.id" class="drag-column col-4 fill-height">
          <span class="drag-column-header">
            <h2>{{ group.name }}</h2>
            <v-chip small color="secondary">
              {{ group.items.length }}
            </v-chip>
          </span>
          <vue-draggable-group v-model="group.items" :groups="groups" :data-id="group.id">
            <ul :id="group.id" class="drag-inner-list col-12 px-0 pt-0 fill-height" :data-id="group.id" @added="added">
              <li v-for="item in group.items" :id="item.ticket_id" :key="item.id" class="drag-item" :data-id="item.id">
                <v-card class="mx-auto pa-0 pb-2" max-width="400">
                  <v-card-title>
                    <v-row>
                      <v-col cols="10" class="py-0">
                        <h5 class="subtitle-2 font-weight-bold pt-1 w100">
                          {{ item.ticket_name }}
                        </h5>
                        <span class="caption font-weight-light w100">{{ $formatTimestamp(item.created_at) }}</span>
                      </v-col>
                      <v-col cols="2" class="py-0 text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn text icon :to="`/ticket/${item.ticket_id}`" target="_blank" v-on="on">
                              <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver detalle del ticket</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-text class="pb-0">
                    <span class="font-weight-bold">Contacto: </span>
                    <span>{{ item.full_name }}</span>
                    <br />
                    <span class="font-weight-bold">Ultima actividad: </span>
                    "<span v-html="item.last_comment"></span>"
                    <span class="caption font-weight-light">({{ $formatTimestamp(item.last_activity) }})</span>
                  </v-card-text>

                  <v-card-actions v-if="item.agent_id && getAgents[item.agent_id]" class="py-0 pr-2">
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-avatar size="30" color="black" class="mr-2" v-on="on">
                          <img
                            v-if="getAgents[item.agent_id].photo"
                            :src="getAgents[item.agent_id].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAgents[item.agent_id].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </template>
                      <span>Asignado a {{ getAgents[item.agent_id].full_name }}</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-card>
              </li>
            </ul>
          </vue-draggable-group>
        </li>
      </ul>
    </div>
    <v-snackbar v-model="snackbar" :bottom="true" :multi-line="true" :right="true" :timeout="10000" dark>
      <v-btn icon color="white" :loading="true"><v-icon size="16">fa-plus</v-icon></v-btn>
      <span class="text--white">{{ snackbar_text }}</span>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TicketsByState",
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      groups: [],
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item",
      },
    };
  },
  computed: {
    ...mapGetters(["getTicketStates", "getAgents"]),
    dragOptions() {
      return {
        animation: 0,
        group: "cards",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    tickets(value) {
      this.loadTickets(value);
    },
  },
  created() {
    this.loadTickets(this.tickets);
  },
  methods: {
    ...mapActions(["setTicketState"]),
    loadTickets(tickets) {
      this.groups = [];

      for (let i = 0; i < this.getTicketStates.length; i++) {
        let state = this.getTicketStates[i];
        this.groups.push({ id: state.id, name: state.name, items: [] });
      }

      for (let i = 0; i < tickets.length; i++) {
        let ticket = tickets[i];
        let ind = this.getTicketStates.findIndex((state) => {
          return state.id === ticket.state;
        });
        this.groups[ind].items.push({
          ...ticket,
          ticket_id: ticket.id,
          id: i,
          groupId: this.getTicketStates[ind].name,
        });
      }
    },
    async added(e) {
      try {
        this.snackbar = true;
        this.snackbar_text = `Cambiando estado a ${e.detail.droptarget.id}`;
        await this.setTicketState({
          ticket_id: e.detail.items[0].id,
          state: e.detail.droptarget.id,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.snackbar = false;
      }
    },
  },
};
</script>
<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li.drag-column ul {
  overflow-x: none !important;
}

.drag-container {
  max-width: 1000px;
  margin: 20px auto;
}

.drag-list {
  display: flex;
  align-items: flex-start;
}

.drag-column {
  margin: 0 5px;
  background: #eee;
  border-radius: 8px;
  overflow: hidden;
  max-height: 85vh;
}

.drag-column h2 {
  font-size: 0.8rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  user-select: none;
}

.drag-inner-list {
  overflow-x: scroll;
  padding-left: 0 !important;
}

.drag-item {
  margin: 8px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}

.drag-item[aria-grabbed="true"] {
  background: #fff;
  opacity: 1 !important;
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  height: 7rem;
  border-radius: 8px;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

@media (max-width: 690px) {
  .drag-column {
    margin-bottom: 30px;
  }
  .drag-list {
    display: block;
  }
}
</style>
