<template>
  <v-app>
    <div v-if="getSales">
      <v-card class="mb-2 mt-4">
        <v-row v-show="showFilters" class="mb-4 mx-0 px-4 py-0">
          <v-col cols="12" sm="4" md="3">
            <v-text-field
              v-model="search"
              label="Buscar cliente"
              single-line
              hide-details
              style="width: 100%"
            ></v-text-field>
            <div class="my-auto hidden-md-and-up">
              <v-btn icon class="mr-0" @click="showFilters = !showFilters">
                <v-icon>fa-filter</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-select
              v-model="statusSelected"
              :items="getPaidStatusToSelect"
              item-text="name"
              item-value="name"
              label="Estado"
              class="px-1"
              @change="addParamToRoute('status', statusSelected)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-select
              v-model="productSelected"
              :items="productsToSelect"
              menu-props="auto"
              label="Producto"
              class="px-1"
              return-object
              @change="addParamToRoute('product', productSelected)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="zoneSelected"
              :items="zonesToSelect"
              label="Zona de reparto"
              class="px-1"
              @change="addParamToRoute('zone', zoneSelected)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center d-flex">
            <v-btn :loading="loadExport" outlined color="primary" dark class="mt-2" @click="exportData">
              Descargar
              <v-icon right size="16">fa-file-download</v-icon>
            </v-btn>
            <v-menu offset-y bottom left class="ml-2">
              <template v-slot:activator="{ on }">
                <v-btn icon class="my-auto" v-on="on">
                  <v-icon small>fa fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item to="/sales/importer">
                  <v-list-item-title>Importar ventas</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-4 mb-5">
        <v-data-table :headers="headers" :items="filterOrders" :search="search" show-expand sort-by class="table-sales">
          <template v-slot:item.created_at="{ item }">
            {{ $formatTimestamp(item.created_at) }}
          </template>
          <template v-slot:item.operations="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="item.address_detail"
                  text
                  icon
                  :href="
                    getMapRoute(item.address_detail.geometry.location.lat, item.address_detail.geometry.location.lng)
                  "
                  target="_blank"
                  v-on="on"
                >
                  <v-icon color="primary" dark small>fas fa-map-marker-alt</v-icon>
                </v-btn>
                <v-btn text icon :to="`/sale/${item.id}`">
                  <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
                </v-btn>
              </template>
              <span>Ver en mapa</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="pa-8">
              <v-row>
                <v-col md="3" cols="12">
                  <v-text-field label="Nombres y Apellidos:" :value="item.name" readonly></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field label="Correo electrónico:" :value="item.email" readonly></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field label="Teléfono" :value="item.phone" readonly></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    label="Fecha de registro"
                    :value="$formatTimestamp(item.created_at)"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-if="item.address_detail"
                    label="Dirección"
                    :value="item.address"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field label="Método de pago:" :value="item.payment_method" readonly></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field label=" Estado del pedido" :value="item.paid_status" readonly></v-text-field>
                  <span v-if="item.paid_status === 'ERROR CULQI'">
                    <br />
                    {{ item.payment_detail.merchant_message }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12"> </v-col>
                <v-col md="6" cols="12">
                  <p>Pedido:</p>
                  <v-simple-table class="mb-4" style="overflow-x:auto;border: 1px solid lightgrey;">
                    <thead class="grey lighten-4">
                      <tr>
                        <th class="text-center">Nombre</th>
                        <th class="text-center">Cantidad</th>
                        <th class="text-center">Sutotal</th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      <tr v-for="(product, i) in item.products" :key="i">
                        <td>{{ product.id }}</td>
                        <td>{{ product.quantity }}</td>
                        <td>{{ product.price * product.quantity }}</td>
                      </tr>
                      <tr>
                        <td colspan=" 2" class="text-right body-1">Total</td>
                        <td class="grey lighten-5">
                          <b class="primary--text subtitle">{{ item.total_paid }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Sales",
  data() {
    return {
      agent: null,
      search: "",
      productsToSelect: ["todos"],
      zonesToSelect: ["todos"],
      productSelected: this.$route.query.product ? this.$route.query.product : "todos",
      statusSelected: this.$route.query.status ? this.$route.query.status : "todos",
      zoneSelected: this.$route.query.zone ? this.$route.query.zone : "todos",
      showFilters: true,
      headers: [
        {
          text: "Nombres y apellidos",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Correo electrónico",
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: "Teléfono",
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: "Zona de reparto",
          align: "center",
          sortable: false,
          value: "zone",
        },
        {
          text: "Fecha",
          align: "center",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Método de pago",
          align: "center",
          sortable: false,
          value: "payment_method",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "paid_status",
        },
        {
          text: "Operación",
          align: "center",
          sortable: false,
          value: "operations",
        },
      ],
      pagination: {
        rowsPerPage: 20,
      },
      loadExport: false,
    };
  },
  computed: {
    ...mapGetters(["getSales", "getZones", "getAgent", "getPaidStatusToSelect", "getProducts"]),
    filterOrders() {
      let data = this.getSales;
      if (this.zoneSelected !== "todos") {
        data = data.filter((order) => {
          return order.zone == this.zoneSelected;
        });
      }
      if (this.productSelected !== "todos") {
        data = data.filter((order) => {
          let find = order.products.find((p) => {
            return p.id == this.productSelected;
          });
          return find;
        });
      }
      if (this.statusSelected !== "todos") {
        data = data.filter((order) => {
          return order.paid_status == this.statusSelected;
        });
      }
      return data;
    },
  },
  watch: {
    getZones(value) {
      if (value) {
        this.zonesToSelect = ["todos"];
        for (var i = 0; i < this.getZones.length; i++) {
          this.zonesToSelect.push(this.getZones[i].id);
        }
      }
    },
  },
  async created() {
    await this.getAllZones();
    await this.getAllSales();
    this.setSaleProducts();
  },
  methods: {
    ...mapActions(["getAllAgents", "getAllZones", "getAllSales", "downloadSales"]),
    getMapRoute(lat, lng) {
      if (!(lat && lng)) return "";
      return `https://maps.google.com/?q=${lat},${lng}`;
    },
    addParamToRoute(param, value) {
      this.$route.query[param] = value;
    },
    setSaleProducts() {
      this.productsToSelect.push(...this.getProducts);
    },
    async exportData() {
      try {
        this.loadExport = true;
        let response = await this.downloadSales();
        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `awy-sales-${this.$moment().format("DD-MM-YYYY-hh-mm")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadExport = false;
      }
    },
  },
};
</script>
<style>
.max-w180 {
  max-width: 180px;
}
.v-avatar {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}
.v-btn {
  text-transform: none;
}
.theme--light.table-sales.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background-color: transparent !important;
}
.table-sales.v-data-table tbody tr.expanded.expanded__content {
  box-shadow: none;
}
.table-sales .v-data-table__mobile-row * {
  font-size: 12px !important;
}
</style>
