import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify";
import VueDraggable from "vue-draggable";
import VueGtm from "vue-gtm";
import VueMoment from "vue-moment";
import moment from "moment";
import "vuetify/dist/vuetify.min.css";
import zhHans from "vuetify/es5/locale/es";
import App from "./App";
import router from "./router";
import store from "./store";
import config from "Config/config";
import Loading from "@/components/inc/Loading";
import { roles } from "@/helpers/get_constants";
import formatTimestamp from "@/helpers/format_timestamp";

import "./registerServiceWorker";

require("moment/locale/es");
moment.locale("es");

const { auth, messaging } = require("./config/firebase");

const EventBus = new Vue();
export default EventBus;

if (messaging)
  messaging.onMessage((payload) => {
    EventBus.$emit("setNotification", payload.data);
  });

Vue.use(VueGtm, {
  id: config.gtm_id,
  enabled: true,
  debug: true,
});
Vue.use(VueMoment, { moment });
Vue.use(Vuetify);
Vue.use(VueDraggable);

Vue.prototype.$roles = roles;
Vue.prototype.$formatTimestamp = formatTimestamp;

Vue.component("ShowLoading", Loading);

const opts = {
  lang: {
    locales: { zhHans },
    current: "zhHans",
  },
  icons: {
    iconfont: "fa",
  },
  theme: config.theme,
};

/* eslint-disable no-new */
auth.onAuthStateChanged(() => {
  new Vue({
    vuetify: new Vuetify(opts),
    el: "#root",
    router,
    store,
    components: { App },
    template: "<App/>",
  });
});
