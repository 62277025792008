<template>
  <v-navigation-drawer v-if="user" v-model="show" expand-on-hover enable-resize-watcher app mobile-break-point="960">
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar v-if="user.photo">
          <v-img :src="user.photo"></v-img>
        </v-avatar>
        <v-avatar v-else color="primary">
          <span class="white--text headline">
            {{ user.full_name ? user.full_name.substring(0, 1) : "" }}
          </span>
        </v-avatar>
      </v-list-item-avatar>
    </v-list-item>
    <v-list-item link two-line>
      <v-list-item-content>
        <v-list-item-title class="subtitle-1">{{ user.full_name }}</v-list-item-title>
        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item v-for="item in items" :key="item.title" link :to="item.path">
        <v-list-item-icon>
          <v-icon small>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list dense>
        <v-list-item link :to="'/settings'">
          <v-list-item-icon class="my-auto">
            <v-icon small>fas fa-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Configuraciones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "DrawerApp",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
      required: false,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      group: null,
    };
  },
  watch: {
    drawer: {
      handler() {
        this.show = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.verifyScreenSize();
  },
  methods: {
    verifyScreenSize() {
      this.show = window.innerWidth >= 960;
    },
  },
};
</script>
