var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-navigation-drawer",
        {
          attrs: {
            "expand-on-hover": "",
            "enable-resize-watcher": "",
            app: "",
            "mobile-break-point": "960"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "v-list",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { link: "", to: "/settings" } },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "my-auto" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fas fa-cog")
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v("Configuraciones")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2299854604
          ),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-avatar",
                [
                  _vm.user.photo
                    ? _c(
                        "v-avatar",
                        [_c("v-img", { attrs: { src: _vm.user.photo } })],
                        1
                      )
                    : _c("v-avatar", { attrs: { color: "primary" } }, [
                        _c("span", { staticClass: "white--text headline" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.user.full_name
                                  ? _vm.user.full_name.substring(0, 1)
                                  : ""
                              ) +
                              "\n        "
                          )
                        ])
                      ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            { attrs: { link: "", "two-line": "" } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "subtitle-1" }, [
                    _vm._v(_vm._s(_vm.user.full_name))
                  ]),
                  _vm._v(" "),
                  _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.user.email))])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.items, function(item) {
              return _c(
                "v-list-item",
                { key: item.title, attrs: { link: "", to: item.path } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(_vm._s(item.icon))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }