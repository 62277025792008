var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-nowrap pa-0",
      staticStyle: { "overflow-x": "scroll" }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "drag-and-drop",
              rawName: "v-drag-and-drop:options",
              value: _vm.options,
              expression: "options",
              arg: "options"
            }
          ],
          staticClass: "col-12 pa-0"
        },
        [
          _c(
            "ul",
            {
              staticClass: "drag-list col-12 pa-0 pb-4 flex-nowrap fill-height"
            },
            _vm._l(_vm.groups, function(group) {
              return _c(
                "li",
                { key: group.id, staticClass: "drag-column col-4 fill-height" },
                [
                  _c(
                    "span",
                    { staticClass: "drag-column-header" },
                    [
                      _c("h2", [_vm._v(_vm._s(group.name))]),
                      _vm._v(" "),
                      _c(
                        "v-chip",
                        { attrs: { small: "", color: "secondary" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(group.items.length) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "vue-draggable-group",
                    {
                      attrs: { groups: _vm.groups, "data-id": group.id },
                      model: {
                        value: group.items,
                        callback: function($$v) {
                          _vm.$set(group, "items", $$v)
                        },
                        expression: "group.items"
                      }
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "drag-inner-list col-12 px-0 pt-0 fill-height",
                          attrs: { id: group.id, "data-id": group.id },
                          on: { added: _vm.added }
                        },
                        _vm._l(group.items, function(item) {
                          return _c(
                            "li",
                            {
                              key: item.id,
                              staticClass: "drag-item",
                              attrs: { id: item.ticket_id, "data-id": item.id }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto pa-0 pb-2",
                                  attrs: { "max-width": "400" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "10" }
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold pt-1 w100"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.ticket_name) +
                                                      "\n                      "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "caption font-weight-light w100"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$formatTimestamp(
                                                        item.created_at
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0 text-right",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    icon: "",
                                                                    to:
                                                                      "/ticket/" +
                                                                      item.ticket_id,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-external-link-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Ver detalle del ticket"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-card-text", { staticClass: "pb-0" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Contacto: ")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.full_name))
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Ultima actividad: ")]
                                    ),
                                    _vm._v('\n                  "'),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(item.last_comment)
                                      }
                                    }),
                                    _vm._v('"\n                  '),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "caption font-weight-light"
                                      },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.$formatTimestamp(
                                                item.last_activity
                                              )
                                            ) +
                                            ")"
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  item.agent_id && _vm.getAgents[item.agent_id]
                                    ? _c(
                                        "v-card-actions",
                                        { staticClass: "py-0 pr-2" },
                                        [
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-avatar",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                size: "30",
                                                                color: "black"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm.getAgents[
                                                              item.agent_id
                                                            ].photo
                                                              ? _c("img", {
                                                                  attrs: {
                                                                    src:
                                                                      _vm
                                                                        .getAgents[
                                                                        item
                                                                          .agent_id
                                                                      ].photo,
                                                                    alt:
                                                                      "Avatar"
                                                                  }
                                                                })
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "white--text headline"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.getAgents[
                                                                            item
                                                                              .agent_id
                                                                          ].full_name.substring(
                                                                            0,
                                                                            1
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "Asignado a " +
                                                    _vm._s(
                                                      _vm.getAgents[
                                                        item.agent_id
                                                      ].full_name
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            bottom: true,
            "multi-line": true,
            right: true,
            timeout: 10000,
            dark: ""
          },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "v-btn",
            { attrs: { icon: "", color: "white", loading: true } },
            [_c("v-icon", { attrs: { size: "16" } }, [_vm._v("fa-plus")])],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "text--white" }, [
            _vm._v(_vm._s(_vm.snackbar_text))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }