<template>
  <div id="root">
    <v-app dark>
      <DrawerApp v-if="user" :user="getAgent" :items="items" :drawer="drawer" />
      <Navbar v-if="user" :user="getAgent" :clipped="clipped" :title="title" :drawer.sync="drawer" />
      <v-content>
        <v-container>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-content>
      <v-snackbar v-if="notification" v-model="showNotification" bottom right multi-line :timeout="6000" elevation="20">
        {{ notification.text }}
        <v-spacer></v-spacer>
        <v-btn v-if="notification.link" primary icon :to="notification.link" target="_blank">
          <v-icon dark small>fas fa-external-link-alt</v-icon>
        </v-btn>
        <v-btn primary icon class="ml-0" @click="showNotification = false">
          <v-icon dark small>fas fa-times</v-icon>
        </v-btn>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { auth, messaging } from "@/config/firebase";
import formatNotification from "@/helpers/format_notification";
import Navbar from "@/components/inc/Navbar";
import DrawerApp from "@/components/inc/DrawerApp";
import EventBus from "./index";

export default {
  name: "App",
  components: {
    Navbar,
    DrawerApp,
  },
  data() {
    return {
      user: {},
      clipped: false,
      title: "",
      drawer: true,
      showNotification: false,
      notification: null,
      items: [
        {
          icon: "fas fa-ticket-alt",
          title: "Tickets",
          path: "/tickets",
          items: [],
        },
        {
          icon: "fas fa-users",
          title: "Contactos",
          path: "/contacts",
          items: [],
        },
        {
          icon: "fas fa-store",
          title: "Ventas",
          path: "/sales",
          items: [],
        },
        {
          icon: "far fa-calendar-alt",
          title: "Eventos",
          path: "/events",
          items: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getAgent"]),
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.title = this.getTitle();
      }
    },
    user(value) {
      if (value && this.$router.currentRoute.name === "login") this.$router.replace("/");
    },
  },
  async created() {
    this.title = this.getTitle();
    this.user = await auth.currentUser;

    if (this.user) {
      await this.getAgentById({ agent: { uid: this.user.uid } });

      if (this.getAgent.role !== this.$roles.ADVISOR)
        this.items.push(
          {
            icon: "fas fa-user-tie",
            title: "Trabajadores",
            path: "/agents",
            items: [],
          },
          {
            icon: "fas fa-chart-line",
            title: "Estadísticas",
            path: "/statistics",
            items: [],
          },
        );

      if (process.env.NODE_ENV === "production" && messaging) this.verifyAllowNotifications();
    }

    EventBus.$on(
      "setNotification",
      function(payload) {
        this.notification = formatNotification({ route: this.$route, ...payload });
        this.showNotification = true;
      }.bind(this),
    );
  },
  methods: {
    ...mapActions(["getAgentById", "updateNotificationToken"]),
    getTitle() {
      return this.$route.meta.title || "";
    },
    async verifyAllowNotifications() {
      let token = "";
      if (!("Notification" in window)) alert("Este navegador no es compatible con las notificaciones de escritorio");
      else if (Notification.permission === "granted") {
        token = await messaging.getToken();
        this.sendTokenToServer(token);
      } else {
        const grant = await Notification.requestPermission();
        if (grant === "granted") {
          token = await messaging.getToken();
          this.sendTokenToServer(token);
        }
      }
    },
    async sendTokenToServer(token) {
      try {
        return await this.updateNotificationToken({
          notification_type: ["assign_tickets"],
          allow: true,
          token: token,
        });
      } catch (error) {
        console.error("notifications/addNotificationToken: ", error);
        this.assign_tickets = this.suscribed_assign_tickets;
      }
    },
  },
};
</script>
