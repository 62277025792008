var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { staticClass: "caption" }, [
      _vm._v(
        "Última actualización: " +
          _vm._s(_vm.$moment(_vm.date_updated).fromNow())
      )
    ]),
    _vm._v(" "),
    _c("iframe", {
      staticStyle: { border: "0" },
      attrs: { src: _vm.report_url, frameborder: "0", allowfullscreen: "" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }