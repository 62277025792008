var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0" },
    [
      _c(
        "v-row",
        { staticClass: "justify-end pr-3" },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { "x-small": "", color: "accent", dark: "" },
                            on: {
                              click: function($event) {
                                _vm.dialog = true
                              }
                            }
                          },
                          on
                        ),
                        [_vm._v("Administrar etiquetas")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "title px-2" },
                    [
                      _c("h5", { staticClass: "pl-2" }, [_vm._v("ETIQUETAS")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.action != "create"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { small: "", color: "primary" },
                              on: { click: _vm.create }
                            },
                            [_vm._v("Nueva etiqueta")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa fa-times")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.openForm
                            ? _c(
                                "v-col",
                                { staticClass: "pb-5", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formTag",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.action == "create"
                                                  ? "Nueva etiqueta"
                                                  : "Editar etiqueta"
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nombre",
                                              placeholder: " ",
                                              rules: _vm.rules.name
                                            },
                                            model: {
                                              value: _vm.tag.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.tag, "name", $$v)
                                              },
                                              expression: "tag.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex justify-center",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-color-picker", {
                                            attrs: {
                                              rules: _vm.rules.required,
                                              "hide-inputs": "",
                                              "dot-size": "12",
                                              type: "hex",
                                              "canvas-height": "100"
                                            },
                                            model: {
                                              value: _vm.tag.color,
                                              callback: function($$v) {
                                                _vm.$set(_vm.tag, "color", $$v)
                                              },
                                              expression: "tag.color"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            { attrs: { color: _vm.tag.color } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tag.name || "etiqueta"
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm.action == "create"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    loading: _vm.loading
                                                  },
                                                  on: { click: _vm.saveTag }
                                                },
                                                [_vm._v("Guardar")]
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    loading: _vm.loading
                                                  },
                                                  on: { click: _vm.saveTag }
                                                },
                                                [_vm._v("Editar")]
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "gray darken-1",
                                                text: ""
                                              },
                                              on: { click: _vm.resetTagForm }
                                            },
                                            [_vm._v("Cancelar")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.getActiveTags.length > 0
                        ? _c(
                            "v-row",
                            [
                              _vm._l(_vm.getActiveTags, function(t, i) {
                                return [
                                  _c(
                                    "v-col",
                                    {
                                      key: i,
                                      staticClass: "d-flex pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2",
                                          attrs: { cols: "9" }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: t.color
                                              }
                                            },
                                            [_vm._v(_vm._s(t.name))]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 text-right",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _vm.auxTag.id != t.id
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "pr-4",
                                                  attrs: { "x-small": "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.update(t)
                                                    }
                                                  }
                                                },
                                                [_vm._v("fas fa-pencil-alt")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { "x-small": "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteTag(t)
                                                }
                                              }
                                            },
                                            [_vm._v("fa fa-trash")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        : _c("h4", { staticClass: "text-center pa-2" }, [
                            _vm._v("No hay etiquetas")
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }