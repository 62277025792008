<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pb-2">
        <Notifications />
      </v-card>
    </v-col>
    <v-col cols="12" class="pt-5 mt-5">
      <v-btn color="accent" text @click="signout">
        <v-icon class="mr-2" small>fas fa-sign-out-alt</v-icon> Cerrar sesión
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
import { messaging } from "@/config/firebase";
import Notifications from "@/views/settings/options/Notifications";

export default {
  name: "Settings",
  components: {
    Notifications,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    ...mapActions(["logout", "updateNotificationToken"]),
    async signout() {
      try {
        const token = await messaging.getToken();
        const response = await this.updateNotificationToken({
          notification_type: ["assign_tickets"],
          allow: false,
          token: token,
          only_token: true,
        });

        if (response.data) this.logout();
      } catch (error) {
        console.error("signout", error);
      }
    },
  },
};
</script>
