var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-col",
        {
          staticClass: "grey--text text--darken-2 font-weight-bold pa-4",
          attrs: { cols: "12" }
        },
        [_vm._v("\n    NOTIFICACIONES\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-switch", {
            attrs: {
              loading: _vm.load["assign_tickets"],
              disabled:
                !_vm.allow_receive_notification || _vm.load["assign_tickets"],
              label:
                "Permitir notificaciones cada vez que se me asigne un nuevo ticket"
            },
            model: {
              value: _vm.assign_tickets,
              callback: function($$v) {
                _vm.assign_tickets = $$v
              },
              expression: "assign_tickets"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }