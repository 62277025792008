var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "contact_register" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "10",
                    md: "8",
                    "offset-sm": "1",
                    "offset-md": "2"
                  }
                },
                [
                  _c("h2", { staticClass: "ml-2" }, [_vm._v("Nuevo contacto")]),
                  _vm._v(" "),
                  _c("ContactForm")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }