import * as MutationsType from "./mutationsType";
/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_TIMELINE](state, payload) {
    state.timeline = payload;
  },
  [MutationsType.ADD_ACTIVITY](state, payload) {
    state.timeline.push(payload);
  },
};
