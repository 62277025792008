export default {
  getTicket(state) {
    return state.ticket;
  },
  getTickets(state) {
    return state.tickets;
  },
  getTicketStates(state) {
    return state.ticketStates;
  },
  getTicketStatesToSelect(state) {
    return [{ id: 0, name: "todos" }, ...state.ticketStates];
  },
  getTicketOrigins(state) {
    return state.ticketOrigins;
  },
  getTicketOriginsToSelect(state) {
    return ["todos", ...state.ticketOrigins];
  },
};
