import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { serverTimestamp, contactsTimelinesCollection, ticketsTimelinesCollection, auth } from "@/config/firebase";

export default {
  // eslint-disable-next-line
  async getContactTimeline({ commit }, payload) {
    contactsTimelinesCollection
      .doc(payload.contact_id)
      .collection("comments")
      .orderBy("created_at", "desc")
      .onSnapshot(async (snap) => {
        commit(MutationsType.SET_TIMELINE, []);
        snap.docs.forEach((doc) => {
          commit(MutationsType.ADD_ACTIVITY, doc.data());
        });
      });
  },
  // eslint-disable-next-line
  async getTicketTimeline({ commit }, payload) {
    ticketsTimelinesCollection
      .doc(payload.ticket_id)
      .collection("comments")
      .orderBy("created_at", "desc")
      .onSnapshot(async (snap) => {
        commit(MutationsType.SET_TIMELINE, []);
        snap.docs.forEach((doc) => {
          commit(MutationsType.ADD_ACTIVITY, doc.data());
        });
      });
  },
  // eslint-disable-next-line
  async addActivity({ commit }, payload) {
    const user = await auth.currentUser;
    const comment = payload.comment;
    let collection, timeline_id;
    let activity = {
      text: comment.text,
      type: comment.type,
      uid: user.uid,
      created_at: serverTimestamp,
    };

    if (activity.type === 4) {
      activity.event_name = comment.event_name;
      activity.event_date = comment.event_date;
      activity.event_time = comment.event_time;
    }

    if (payload.contact_id) {
      collection = contactsTimelinesCollection;
      timeline_id = payload.contact_id;
    } else {
      collection = ticketsTimelinesCollection;
      timeline_id = payload.ticket_id;
      activity.ticket_id = payload.ticket_id;
    }

    collection
      .doc(timeline_id)
      .collection("comments")
      .doc()
      .set(activity);
  },
  // eslint-disable-next-line
  async exportContactActivityTemplate({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact/export-template-activities",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importContactActivities({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/contact/import-activities",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportTicketActivityTemplate({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/export-template-activities",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importTicketActivities({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/import-activities",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
