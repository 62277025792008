var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "agent_detail" } },
    [
      _vm.agent && _vm.role && _vm.getAgentSelected && _vm.getAgentRolesToArray
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "sticky-75" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.tabs,
                                callback: function($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs"
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                INFORMACIÓN\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3"
                                        },
                                        [
                                          _vm.editInformation
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("h4", [
                                                    _vm._v(
                                                      "Editar información del trabajado"
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editInformation
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("ContactForm", {
                                                    attrs: { action: "update" },
                                                    on: {
                                                      cancelInformation:
                                                        _vm.cancelInformation
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fill-height text-center"
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.agentFields,
                                                        function(
                                                          agentField,
                                                          i
                                                        ) {
                                                          return [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                key: i,
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .getAgentSelected[
                                                                          agentField
                                                                            .field
                                                                        ],
                                                                      label:
                                                                        agentField.label,
                                                                      readonly: !_vm.editInformation
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                ROL\n                "
                                      ),
                                      _vm.role === _vm.$roles.SUPER_ADMIN &&
                                      !_vm.editRole &&
                                      _vm.agent.uid !== _vm.getAgentSelected.uid
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "pa-2 mr-4",
                                              staticStyle: {
                                                "font-size": "16px",
                                                position: "absolute",
                                                "z-index": "10000",
                                                right: "35px"
                                              },
                                              attrs: { size: "16" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.editRole = true
                                                }
                                              }
                                            },
                                            [_vm._v("fas fa-pencil-alt")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "formRole",
                                          attrs: { "lazy-validation": "" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "fill-height text-center px-3 pt-2"
                                            },
                                            [
                                              _vm.editRole
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          "Editar rol del Trabajador"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "pt-2",
                                                    staticStyle: {
                                                      "font-size": "15px"
                                                    },
                                                    attrs: {
                                                      value:
                                                        _vm.getAgentSelected
                                                          .role,
                                                      items:
                                                        _vm.getAgentRolesToArray,
                                                      readonly: !_vm.editRole,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: "Estado"
                                                    },
                                                    model: {
                                                      value: _vm.agent_role,
                                                      callback: function($$v) {
                                                        _vm.agent_role = $$v
                                                      },
                                                      expression: "agent_role"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.editRole
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            rounded: "",
                                                            color: "accent"
                                                          },
                                                          on: {
                                                            click: _vm.saveRole
                                                          }
                                                        },
                                                        [_vm._v("Guardar")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            rounded: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.cancelRole
                                                          }
                                                        },
                                                        [_vm._v("Cancelar")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }