import * as MutationsType from "./mutationsType";
import requestAxios from "@/helpers/request_axios";
import { roles } from "@/helpers/get_constants";
import getRole from "@/helpers/get_role";

const { auth, contactsCollection } = require("@/config/firebase");

export default {
  async getContactById({ commit }, payload) {
    try {
      await contactsCollection.doc(payload.contact_id).onSnapshot(async (doc) => {
        let contact = { id: doc.id, ...doc.data() };
        commit(MutationsType.SET_CONTACT, contact);
      });
    } catch (error) {
      console.error("getContactById: ", error);
    }
  },
  async getAllContacts({ commit }) {
    let agent = auth.currentUser;
    let agent_role = await getRole(agent);
    let is_advisor = roles.ADVISOR === agent_role;

    let reference = is_advisor ? contactsCollection.where("agent_id", "==", agent.uid) : contactsCollection;
    await reference.orderBy("created_at", "desc").onSnapshot(async (snap) => {
      let data;
      commit(MutationsType.SET_CONTACTS, []);
      snap.docs.forEach((doc) => {
        data = doc.data();
        commit(MutationsType.ADD_CONTACT, { id: doc.id, ...data });
      });
    });
  },
  // eslint-disable-next-line
  async createContact({ commit }, payload) {
    try {
      let agent = auth.currentUser;
      let response = await requestAxios({
        url: "/awy/api/v1/contact",
        method: "POST",
        data: {
          origin: "agent",
          created_uid: agent.uid,
          ...payload.contact,
        },
      });
      return response.data;
    } catch (error) {
      console.error("createContact: ", error);
      return error.response.data;
    }
  },
  // eslint-disable-next-line
  async setContactAgent({ commit }, payload) {
    try {
      await contactsCollection.doc(payload.contact_id).update({
        agent_id: payload.agent.id,
        agent: {
          uid: payload.agent.id,
          full_name: payload.agent.full_name,
          email: payload.agent.email,
        },
      });
    } catch (error) {
      console.error("setContactAgent: ", error);
    }
  },
  // eslint-disable-next-line
  async setContactTerms({ commit }, payload) {
    try {
      return await requestAxios({
        url: "/awy/api/v1/contact/set-terms",
        method: "POST",
        data: payload,
      });
    } catch (error) {
      console.error("setContactTerms: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async updateContact({ commit }, payload) {
    try {
      let user = await auth.currentUser;
      let response = await requestAxios({
        url: `/awy/api/v1/contact/${payload.contact_id}`,
        method: "PATCH",
        data: {
          ...payload.data,
          agent_id: user.uid,
        },
      });
      return response.data;
    } catch (error) {
      console.error("updateContact: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async downloadContacts({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact/download",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("downloadContacts: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportContactTemplate({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact/export-template",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("exportContactTemplate: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importContacts({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/contact/import",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("importContacts: ", error);
      throw error.response.data;
    }
  },
};
