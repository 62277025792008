const roles = {
  ADVISOR: "advisor",
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
};

const notification_types = {
  ASSIGN_TICKETS: "assign_tickets",
};

export { roles, notification_types };
