var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.getSales
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "mb-2 mt-4" },
                [
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showFilters,
                          expression: "showFilters"
                        }
                      ],
                      staticClass: "mb-4 mx-0 px-4 py-0"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4", md: "3" } },
                        [
                          _c("v-text-field", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              label: "Buscar cliente",
                              "single-line": "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "my-auto hidden-md-and-up" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showFilters = !_vm.showFilters
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("fa-filter")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4", md: "2" } },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              items: _vm.getPaidStatusToSelect,
                              "item-text": "name",
                              "item-value": "name",
                              label: "Estado"
                            },
                            on: {
                              change: function($event) {
                                return _vm.addParamToRoute(
                                  "status",
                                  _vm.statusSelected
                                )
                              }
                            },
                            model: {
                              value: _vm.statusSelected,
                              callback: function($$v) {
                                _vm.statusSelected = $$v
                              },
                              expression: "statusSelected"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4", md: "2" } },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              items: _vm.productsToSelect,
                              "menu-props": "auto",
                              label: "Producto",
                              "return-object": ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.addParamToRoute(
                                  "product",
                                  _vm.productSelected
                                )
                              }
                            },
                            model: {
                              value: _vm.productSelected,
                              callback: function($$v) {
                                _vm.productSelected = $$v
                              },
                              expression: "productSelected"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4", md: "3" } },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              items: _vm.zonesToSelect,
                              label: "Zona de reparto"
                            },
                            on: {
                              change: function($event) {
                                return _vm.addParamToRoute(
                                  "zone",
                                  _vm.zoneSelected
                                )
                              }
                            },
                            model: {
                              value: _vm.zoneSelected,
                              callback: function($$v) {
                                _vm.zoneSelected = $$v
                              },
                              expression: "zoneSelected"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center d-flex",
                          attrs: { cols: "12", sm: "4", md: "2" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                loading: _vm.loadExport,
                                outlined: "",
                                color: "primary",
                                dark: ""
                              },
                              on: { click: _vm.exportData }
                            },
                            [
                              _vm._v("\n            Descargar\n            "),
                              _c(
                                "v-icon",
                                { attrs: { right: "", size: "16" } },
                                [_vm._v("fa-file-download")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              staticClass: "ml-2",
                              attrs: { "offset-y": "", bottom: "", left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "my-auto",
                                              attrs: { icon: "" }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("fa fa-ellipsis-v")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2388796332
                              )
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/sales/importer" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Importar ventas")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "pa-4 mb-5" },
                [
                  _c("v-data-table", {
                    staticClass: "table-sales",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.filterOrders,
                      search: _vm.search,
                      "show-expand": "",
                      "sort-by": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.created_at",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$formatTimestamp(item.created_at)
                                  ) +
                                  "\n        "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.operations",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            item.address_detail
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        icon: "",
                                                        href: _vm.getMapRoute(
                                                          item.address_detail
                                                            .geometry.location
                                                            .lat,
                                                          item.address_detail
                                                            .geometry.location
                                                            .lng
                                                        ),
                                                        target: "_blank"
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fas fa-map-marker-alt"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  icon: "",
                                                  to: "/sale/" + item.id
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                      small: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "fas fa-external-link-alt"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Ver en mapa")])
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "expanded-item",
                          fn: function(ref) {
                            var item = ref.item
                            var headers = ref.headers
                            return [
                              _c(
                                "td",
                                {
                                  staticClass: "pa-8",
                                  attrs: { colspan: headers.length }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3", cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nombres y Apellidos:",
                                              value: item.name,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3", cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Correo electrónico:",
                                              value: item.email,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3", cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Teléfono",
                                              value: item.phone,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3", cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Fecha de registro",
                                              value: _vm.$formatTimestamp(
                                                item.created_at
                                              ),
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { md: "6", cols: "12" } },
                                        [
                                          item.address_detail
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  label: "Dirección",
                                                  value: item.address,
                                                  readonly: ""
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3", cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Método de pago:",
                                              value: item.payment_method,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3", cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: " Estado del pedido",
                                              value: item.paid_status,
                                              readonly: ""
                                            }
                                          }),
                                          _vm._v(" "),
                                          item.paid_status === "ERROR CULQI"
                                            ? _c("span", [
                                                _c("br"),
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      item.payment_detail
                                                        .merchant_message
                                                    ) +
                                                    "\n                "
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", {
                                        attrs: { md: "6", cols: "12" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "6", cols: "12" } },
                                        [
                                          _c("p", [_vm._v("Pedido:")]),
                                          _vm._v(" "),
                                          _c(
                                            "v-simple-table",
                                            {
                                              staticClass: "mb-4",
                                              staticStyle: {
                                                "overflow-x": "auto",
                                                border: "1px solid lightgrey"
                                              }
                                            },
                                            [
                                              _c(
                                                "thead",
                                                {
                                                  staticClass: "grey lighten-4"
                                                },
                                                [
                                                  _c("tr", [
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Nombre")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Cantidad")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Sutotal")]
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._l(
                                                    item.products,
                                                    function(product, i) {
                                                      return _c(
                                                        "tr",
                                                        { key: i },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(product.id)
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                product.quantity
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                product.price *
                                                                  product.quantity
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right body-1",
                                                        attrs: { colspan: " 2" }
                                                      },
                                                      [_vm._v("Total")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "grey lighten-5"
                                                      },
                                                      [
                                                        _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "primary--text subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.total_paid
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3403909489
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }