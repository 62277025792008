var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "importer_activities" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "10",
                    md: "8",
                    "offset-sm": "1",
                    "offset-md": "2"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [
                          _vm._v("Importador de actividades de Tickets")
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "8" } }, [
                        _c(
                          "h5",
                          { staticClass: "font-weight-regular subtitle-2" },
                          [
                            _vm._v("\n              Primero debes "),
                            _c("strong", [
                              _vm._v("descargar la plantilla de importación")
                            ]),
                            _vm._v(
                              " la cual contiene los datos\n              necesario para importar actividades de los tickets.\n            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-sm-right",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", outlined: "" },
                              on: { click: _vm.exportTemplate }
                            },
                            [_vm._v("Descargar plantilla")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { dense: "", text: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "4" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3"
                                        },
                                        [_vm._v("Tipos de actividades:")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "3" } },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2 text-sm-right"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pr-1",
                                              attrs: { size: "18" }
                                            },
                                            [_vm._v("fas fa-envelope")]
                                          ),
                                          _vm._v(" mensaje\n                  ")
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "2" } },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2 text-sm-center"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pr-1",
                                              attrs: { size: "18" }
                                            },
                                            [_vm._v("fas fa-phone")]
                                          ),
                                          _vm._v(" llamada\n                  ")
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "3" } },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pr-1",
                                              attrs: { size: "18" }
                                            },
                                            [_vm._v("fas fa-comments")]
                                          ),
                                          _vm._v(
                                            " comentario\n                  "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "formImporterActivities",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "8" } },
                                    [
                                      _c("v-file-input", {
                                        attrs: {
                                          chips: "",
                                          label:
                                            "Seleccione archivo a importar",
                                          placeholder: "solo archivos .xlsx",
                                          "truncate-length": "50",
                                          "prepend-icon": "",
                                          "append-outer-icon":
                                            "fa fa-paperclip",
                                          accept: ".xlsx",
                                          rules: _vm.rules.required
                                        },
                                        model: {
                                          value: _vm.file,
                                          callback: function($$v) {
                                            _vm.file = $$v
                                          },
                                          expression: "file"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.loading
                                          },
                                          on: { click: _vm.imports }
                                        },
                                        [_vm._v("Importar actividades")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.response_status !== null
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "8" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                text: "",
                                                type: _vm.response_status
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.response_message) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.response_data
                                            ? _c(
                                                "v-row",
                                                [
                                                  _vm._l(
                                                    _vm.response_data,
                                                    function(r, i) {
                                                      return [
                                                        r.error
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                key: i,
                                                                staticClass:
                                                                  "py-1",
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "body-2 font-weight-bold"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Fila " +
                                                                            _vm._s(
                                                                              r
                                                                                .error
                                                                                .row
                                                                            ) +
                                                                            ":"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "h5",
                                                                      {
                                                                        staticClass:
                                                                          "body-2 pl-3"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            r.message
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-alert",
                                                                  {
                                                                    staticClass:
                                                                      "caption my-1",
                                                                    attrs: {
                                                                      dense: "",
                                                                      outlined:
                                                                        "",
                                                                      type:
                                                                        "error"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        r.error
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }