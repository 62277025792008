var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "contacts" } },
    [
      _vm.getAgent && _vm.getAgents && _vm.getContacts
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "mb-2 mt-4" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      active: _vm.loadExport,
                      indeterminate: _vm.loadExport,
                      absolute: "",
                      top: "",
                      color: "secondary accent-4"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "px-4 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showFilters,
                              expression: "showFilters"
                            }
                          ]
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Buscar contacto",
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "my-auto hidden-md-and-up" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-0",
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showFilters = !_vm.showFilters
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("filter_list")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "2" } },
                            [
                              _c("v-select", {
                                staticClass: "px-1",
                                attrs: {
                                  items: _vm.countryArray,
                                  "menu-props": "auto",
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "País",
                                  "return-object": ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.addParamToRoute(
                                      "country",
                                      _vm.countrySelected
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.countrySelected,
                                  callback: function($$v) {
                                    _vm.countrySelected = $$v
                                  },
                                  expression: "countrySelected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4", md: "3" } },
                            [
                              _vm.getAgent.role === _vm.$roles.ADVISOR
                                ? _c("v-text-field", {
                                    attrs: { label: "Encargado", readonly: "" },
                                    model: {
                                      value: _vm.getAgent.full_name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.getAgent, "full_name", $$v)
                                      },
                                      expression: "getAgent.full_name"
                                    }
                                  })
                                : _c("v-select", {
                                    staticClass: "px-1",
                                    attrs: {
                                      items: _vm.agentsArray,
                                      "item-text": "full_name",
                                      "item-value": "uid",
                                      label: "Encargado"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.addParamToRoute(
                                          "agent",
                                          _vm.agentSelected
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.agentSelected,
                                      callback: function($$v) {
                                        _vm.agentSelected = $$v
                                      },
                                      expression: "agentSelected"
                                    }
                                  })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center d-flex",
                              attrs: { cols: "12", sm: "4", md: "2" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    loading: _vm.loadExport,
                                    outlined: "",
                                    color: "primary",
                                    dark: ""
                                  },
                                  on: { click: _vm.exportData }
                                },
                                [
                                  _vm._v(
                                    "\n              Descargar\n              "
                                  ),
                                  _c(
                                    "v-icon",
                                    { attrs: { right: "", size: "16" } },
                                    [_vm._v("fa-file-download")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-menu",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "offset-y": "",
                                    bottom: "",
                                    left: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "my-auto",
                                                  attrs: { icon: "" }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("fa fa-ellipsis-v")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2388796332
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        { attrs: { to: "/contacts/importer" } },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Importar contactos")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            to: "/contacts/importer-activities"
                                          }
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Importar actividades")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-5 pb-5" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.filterContacts,
                            search: _vm.search,
                            "items-per-page": 30,
                            "sort-by": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.created_at",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$formatTimestamp(item.created_at)
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.agent_id",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          item.agent_id
                                            ? _vm.getAgents[item.agent_id].name
                                            : "Sin asignar"
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.operations",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          text: "",
                                          icon: "",
                                          to: "/contact/" + item.id
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "primary",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          [_vm._v("fas fa-external-link-alt")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3580593051
                          )
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("v-alert", {
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "no-results",
                                  fn: function() {
                                    return [
                                      _vm._v(
                                        'Your search for "' +
                                          _vm._s(_vm.search) +
                                          '" found no results.'
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2478838959
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    to: "/contact/register",
                    fab: "",
                    dark: "",
                    fixed: "",
                    bottom: "",
                    right: "",
                    color: "secondary"
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "16" } }, [
                    _vm._v("fa-user-plus")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }