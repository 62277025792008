<template>
  <v-app id="ticket_detail">
    <v-row v-if="getAgent && getTicket && getTicketStates && getAgents">
      <!-- Ticket information -->
      <v-col cols="12" md="5">
        <v-row class="sticky-75">
          <v-col cols="12">
            <v-expansion-panels v-model="tabs" multiple>
              <!-- Información -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  INFORMACIÓN
                  <div v-if="!editInformation" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-4" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editInformation = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar información</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="formInformation" lazy-validation>
                    <v-row class="fill-height text-center px-3 pt-2">
                      <v-col v-if="editInformation" cols="12">
                        <h4>Editar información del Ticket</h4>
                      </v-col>
                      <v-col v-if="editInformation" cols="12">
                        <TicketForm action="update" @cancelInformation="cancelInformation" />
                      </v-col>
                      <v-col v-else cols="12">
                        <v-row class="fill-height text-center">
                          <v-col cols="12" class="pt-0">
                            <span class="caption">ID TICKET:</span>
                            <v-chip color="accent" :ripple="false" small>{{ ticket.id }}</v-chip>
                          </v-col>
                          <template v-for="(ticketField, i) in ticketFields">
                            <v-col :key="i" cols="12">
                              <v-text-field
                                :value="getTicket[ticketField.field]"
                                :label="ticketField.label"
                                :readonly="!editInformation"
                              ></v-text-field>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Estado -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ESTADO
                  <div v-if="!editState" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon small class="mr-4" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editState = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar estado</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="formState" lazy-validation>
                    <v-row class="fill-height text-center px-3 pt-2">
                      <v-col v-if="editState" cols="12">
                        <h4>Editar estado del Ticket</h4>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="state"
                          :value="getTicket.state"
                          :items="getTicketStates"
                          :readonly="!editState"
                          style="font-size: 15px;"
                          item-text="name"
                          item-value="id"
                          label="Estado"
                          class="pt-2"
                        ></v-select>
                      </v-col>
                      <v-col v-if="reasons && reasons.length > 0" cols="12">
                        <v-select
                          v-model="stateReason"
                          :items="reasons"
                          :rules="rules.required"
                          :readonly="!editState"
                          style="font-size: 15px;"
                          label="Motivo"
                          class="pt-0"
                        ></v-select>
                      </v-col>
                      <v-col v-if="editState" cols="12">
                        <v-btn small rounded color="accent" @click="saveState">Guardar</v-btn>
                        <v-btn small rounded @click="cancelState">Cancelar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Contacto -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  CONTACTO
                  <div class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          size="16"
                          icon
                          small
                          class="pa-2 mr-4"
                          target="_blank"
                          :to="`/contact/${getTicket.contact_id}`"
                          v-on="on"
                        >
                          <v-icon size="16">fas fa-external-link-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver contacto</span>
                    </v-tooltip>
                    <v-tooltip v-if="!editContact" top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-4" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editContact = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar contacto</span>
                    </v-tooltip>
                  </div>
                  <!--
                  <v-icon
                    size="16"
                    class="pa-2 mr-4"
                    style="font-size: 16px;position: absolute;z-index: 10000;right: 35px;"
                    @click.stop.prevent="editState = true"
                    >fas fa-external-link-alt</v-icon
                  >
                  -->
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="formContact" lazy-validation>
                    <v-row class="fill-height text-center px-3 pt-2">
                      <v-col v-if="editContact" cols="12">
                        <h4>Editar información del Contacto</h4>
                      </v-col>
                      <v-col v-if="editContact" cols="12">
                        <ContactForm action="update" @cancelInformation="cancelContact" />
                      </v-col>
                      <v-col v-else cols="12">
                        <v-row class="fill-height text-center">
                          <template v-for="(contactField, i) in contactFields">
                            <v-col :key="i" cols="12">
                              <v-text-field
                                :value="getContact[contactField.field]"
                                :label="contactField.label"
                                :readonly="!editContact"
                              ></v-text-field>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Etiquetas -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ETIQUETAS
                  <div v-if="!editTags" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-4" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editTags = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar etiquetas</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form lazy-validation>
                    <v-row class="text-center px-3 pt-2">
                      <v-col v-if="editTags" cols="12">
                        <h4>Editar etiquetas del ticket</h4>
                      </v-col>
                      <v-col cols="12">
                        <template>
                          <v-combobox
                            v-model="tags"
                            :items="getActiveTags"
                            :disabled="!editTags"
                            chips
                            label="Etiquetas"
                            multiple
                            class="h-auto"
                            item-id="name"
                            item-text="name"
                            return-object
                            @change="changeTags"
                          >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                              <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                :close="editTags"
                                :color="item.color"
                                @click="select"
                                @click:close="removeTag(item)"
                              >
                                <strong>{{ item.name }}</strong
                                >&nbsp;
                                <span></span>
                              </v-chip>
                            </template>
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    El tag no existe. Presiona <kbd>enter</kbd> para crearlo.
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>
                        </template>
                      </v-col>
                      <TagModal />
                      <v-col v-if="editTags" cols="12">
                        <v-btn small rounded color="accent" :loading="loadSaveTags" @click="saveTags">Guardar</v-btn>
                        <v-btn small rounded @click="cancelTags()">Cancelar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Asistencia -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ASISTENCIA
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3 pt-2">
                    <v-col cols="12" class="d-flex">
                      <div v-if="getTicket.agent_id && !loadSaveAgent">
                        <v-avatar size="50px" color="black">
                          <img
                            v-if="getAgents[getTicket.agent_id].photo"
                            :src="getAgents[getTicket.agent_id].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAgents[getTicket.agent_id].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </div>
                      <div v-else>
                        <v-avatar size="50px" color="black">
                          <span class="white--text headline">-</span>
                        </v-avatar>
                      </div>
                      <v-text-field
                        v-if="getAgent.role === $roles.ADVISOR"
                        v-model="getAgent.full_name"
                        label="Encargado"
                        class="pl-2"
                        readonly
                      >
                      </v-text-field>
                      <v-select
                        v-else
                        v-model="agentTicket"
                        :items="getAgentsToArray"
                        :disabled="loadSaveAgent"
                        item-text="full_name"
                        item-value="uid"
                        :value="getTicket.agent_id"
                        label="Encargado"
                        placeholder="Sin asignar"
                        class="pl-2 w95"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Fuente -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  FUENTE
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3 pt-2">
                    <v-col class="size" cols="12">
                      <div>
                        Obtenido desde <strong>{{ getTicket.origin }}</strong>
                      </div>
                    </v-col>
                    <v-col v-if="getTicket.created_uid" class="d-flex" cols="12">
                      <div>
                        <v-avatar size="50px" color="black">
                          <img
                            v-if="getAgents[getTicket.created_uid].photo"
                            :src="getAgents[getTicket.created_uid].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAgents[getTicket.created_uid].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </div>
                      <v-text-field
                        :value="getAgents[getTicket.created_uid].full_name"
                        label="Registrado por:"
                        class="pl-2 w95"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
      <!-- Timeline -->
      <v-col cols="12" md="7">
        <v-row>
          <!-- Box -->
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">
                HISTORIAL DE SEGUIMIENTO AL TICKET
              </v-col>
              <v-col cols="12 pa-4">
                <v-form ref="formActivity" lazy-validation>
                  <v-row>
                    <v-col cols="12 pa-4">
                      <v-textarea
                        v-model="comment.text"
                        name="input"
                        label="Escribe tu comentario"
                        auto-grow
                        rows="1"
                        placeholder=" "
                        :rules="rules.required"
                      ></v-textarea>
                    </v-col>
                    <v-col v-if="comment.type === 4" cols="12" class="py-0 px-4">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="comment.event_name"
                            label="Nombre del evento"
                            placeholder=" "
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-menu
                            v-model="menu_comment_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="300px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="comment.event_date"
                                label="Fecha del evento"
                                placeholder="Seleccione"
                                prepend-icon="fa fa-calendar"
                                readonly
                                :rules="rules.required"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="comment.event_date"
                              :min="$moment().format('YYYY-MM-DD')"
                              @input="menu_comment_date = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-dialog
                            ref="dialog"
                            v-model="dialog_comment_time"
                            :return-value.sync="comment.event_time"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="comment.event_time"
                                label="Hora del evento"
                                placeholder="Seleccione"
                                prepend-icon="fas fa-clock"
                                readonly
                                :rules="rules.required"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker v-if="dialog_comment_time" v-model="comment.event_time" full-width>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="dialog_comment_time = false">Cancelar</v-btn>
                              <v-btn text color="primary" @click="$refs.dialog.save(comment.event_time)">Aceptar</v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-radio-group v-model="comment.type" row style="height: 30px;">
                        <v-icon class="pr-1" size="18">fas fa-comments</v-icon>
                        <v-radio color="grey darken-1" :value="0" selected></v-radio>
                        <v-icon class="pr-1" size="18">fas fa-phone</v-icon>
                        <v-radio color="grey darken-1" :value="1"></v-radio>
                        <v-icon class="pr-1" size="18">fas fa-envelope</v-icon>
                        <v-radio color="grey darken-1" :value="2"></v-radio>
                        <v-icon class="pr-1" size="18">far fa-calendar-alt</v-icon>
                        <v-radio color="grey darken-1" :value="4"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col class="text-right" cols="12" md="6">
                      <v-btn color="primary" class="mt-0" rounded @click="commentTimeline">Comentar</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-card>
          </v-col>
          <!-- Comments -->
          <v-col cols="12">
            <template>
              <v-card class="mx-auto">
                <v-card-text class="py-0">
                  <v-col cols="12" sm="10" offset-sm="1">
                    <v-timeline v-if="getTimeline && getTimeline.length > 0" align-top dense>
                      <v-slide-x-reverse-transition group hide-on-leave>
                        <template v-for="(c, index) in getTimeline">
                          <v-timeline-item :key="index" :icon="iconTypeComment[c.type]" color="primary" large>
                            <v-card class="px-2">
                              <v-row class="pa-2">
                                <v-col v-if="c.created_at" class="grey--text text--darken-1" cols="12">
                                  {{ $formatTimestamp(c.created_at, true) }}
                                </v-col>
                                <v-col cols="12" sm="8" offset-sm="2" class="font-italic pb-2">
                                  "<span v-html="c.text_formatted || c.text"></span>"
                                </v-col>
                                <v-col v-if="c.order_id" cols="12" class="pb-2">
                                  <v-btn :to="`/sale/${c.order_id}`" small color="accent" target="_blank">
                                    <v-icon dark small class="pr-2">fas fa-external-link-alt</v-icon>
                                    Detalle de compra
                                  </v-btn>
                                </v-col>
                                <div v-if="getAgents[c.uid]" class="d-flex pa-1">
                                  <div>
                                    <v-avatar size="25px" color="black">
                                      <img v-if="getAgents[c.uid].photo" :src="getAgents[c.uid].photo" alt="Avatar" />
                                      <span v-else class="white--text headline">
                                        {{ getAgents[c.uid].full_name.substring(0, 1) }}
                                      </span>
                                    </v-avatar>
                                  </div>
                                  <h6 class="pt-1 pl-2 caption grey--text text--darken-2">
                                    <span>{{ getAgents[c.uid].name }}</span>
                                    <span v-if="c.type == 1">realizó la llamada.</span>
                                    <span v-else-if="c.type == 2">envió el correo.</span>
                                    <span v-else-if="c.type == 4"
                                      >agendó un evento para el día {{ c.event_date }} a las {{ c.event_time }}</span
                                    >
                                    <span v-else>agregó la nota.</span>
                                  </h6>
                                </div>
                              </v-row>
                            </v-card>
                          </v-timeline-item>
                        </template>
                      </v-slide-x-reverse-transition>
                    </v-timeline>
                    <div v-else class="text-center py-4">Aún no hay comentarios</div>
                  </v-col>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";
import getRandomColor from "@/helpers/get_randomcolor";
import TicketForm from "@/components/forms/TicketForm";
import ContactForm from "@/components/forms/ContactForm";
import TagModal from "@/components/modals/TagModal";

const { auth } = require("@/config/firebase");

export default {
  name: "TicketDetail",
  components: {
    TicketForm,
    ContactForm,
    TagModal,
  },
  data() {
    return {
      agent: null,
      ticket: {
        id: this.$route.params.id,
      },
      comment: {
        text: "",
        type: 0,
        created_at: "",
        event_name: "",
        event_date: "",
      },
      iconTypeComment: [
        "fas fa-comment",
        "fas fa-phone-square",
        "fas fa-envelope",
        "fas fa-user",
        "far fa-calendar-alt",
      ],
      tabs: [0, 1, 2],
      editState: false,
      editInformation: false,
      editTags: false,
      editContact: false,
      loadSaveInformation: false,
      loadSaveTags: false,
      loadSaveAgent: false,
      tags: [],
      reasons: [],
      state: 0,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
      ticketFields: [],
      contactFields: [],
      menu_comment_date: false,
      dialog_comment_time: false,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getAgents",
      "getAgentsToArray",
      "getTicket",
      "getTicketStates",
      "getTimeline",
      "getTags",
      "getActiveTags",
      "getContact",
    ]),
    agentTicket: {
      get() {
        return this.getTicket ? this.getTicket.agent_id : 0;
      },
      set(value) {
        if (this.getTicket.agent_id !== value) this.saveAgent(value);
      },
    },
  },
  watch: {
    async getTicket(value) {
      this.state = value.state;
      await this.getContactById({ contact_id: value.contact_id });
    },
    state(value) {
      let state = this.getTicketStates.find((s) => {
        return s.id === value;
      });
      if (state.reasons) {
        this.reasons = state.reasons;
        this.stateReason = this.getTicket.state_reason || "";
      } else {
        this.reasons = [];
        this.stateReason = "";
      }
    },
    getTags() {
      if (!this.loadSaveTags) this.tags = this.setTags(this.getTicket.tags);
    },
  },
  async mounted() {
    this.agent = await auth.currentUser;
    await this.getTicketById({ ticket_id: this.ticket.id });
    await this.findAgent(this.agent.uid);
    await this.getTicketTimeline({ ticket_id: this.ticket.id });
    await this.getAllTags();
    this.getTicketFields();
    this.getContactFields();
    this.getAllAgents(false);
    this.agent = { name: this.getAgent.name, full_name: this.getAgent.full_name, photo: this.getAgent.photo };
  },
  methods: {
    ...mapActions([
      "getTicketById",
      "getContactById",
      "addActivity",
      "setAgent",
      "setTicketState",
      "updateTicket",
      "findAgent",
      "getTicketTimeline",
      "setTicketAgent",
      "getAllAgents",
      "getAllTags",
      "createTag",
      "updateTag",
    ]),
    getTicketFields() {
      let schema = config.ticketFormSchema;
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          this.ticketFields.push({ field: field, ...schema[field] });
        }
      }
    },
    getContactFields() {
      let schema = config.contactFormSchema;
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          this.contactFields.push({ field: field, ...schema[field] });
        }
      }
    },
    async commentTimeline() {
      if (this.$refs.formActivity.validate()) {
        let data = {
          ticket_id: this.ticket.id,
          comment: this.comment,
          timeline: this.getTimeline,
        };
        this.addActivity(data);
        this.resetComment();
      }
    },
    resetComment() {
      this.comment = Object.assign(
        {},
        {
          text: "",
          type: 0,
          created_at: "",
          event_name: "",
          event_date: "",
          event_time: "",
        },
      );
      this.$refs.formActivity.resetValidation();
    },
    formatCurrency(number, symbol = "S/.") {
      if (isNaN(number)) number = 0;
      return `${symbol} ${parseFloat(number)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
    },
    saveState() {
      if (this.$refs.formState.validate()) {
        this.editState = false;
        this.setTicketState({
          ticket_id: this.getTicket.id,
          state: this.state,
          state_reason: this.stateReason,
        });
      }
    },
    cancelState() {
      this.editState = false;
      this.state = this.getTicket.state;
      this.stateReason = this.getTicket.state_reason || "";
    },
    cancelInformation() {
      this.editInformation = false;
    },
    cancelContact() {
      this.editContact = false;
    },
    setTags(tags) {
      let formattedTags = [];

      if (this.getTags && tags) {
        for (let i = 0; i < tags.length; i++) {
          let ind = this.getTags.findIndex((item) => item.id == tags[i]);
          formattedTags.push(this.getTags[ind]);
        }
      }

      return formattedTags;
    },
    changeTags(e) {
      if (e.length > 0) {
        let last = e[e.length - 1];
        if (!last.color) this.tags[e.length - 1] = { id: last, name: last, color: getRandomColor(), active: true };
      }
    },
    async saveTags() {
      try {
        this.loadSaveTags = true;

        let tagsToSave = [],
          auxTag,
          isRepeated,
          promises = [];

        for (let i = 0; i < this.tags.length; i++) {
          auxTag = Object.assign({}, this.tags[i]);
          isRepeated = this.getTags.find((item) => {
            return item.name === auxTag.name && item.active;
          });

          if (isRepeated) tagsToSave.push(auxTag.id);
          else promises.push(this.createTag({ tag: auxTag }));
        }

        let createdTag = await Promise.all(await promises);
        tagsToSave.push(...createdTag);

        const response = await this.updateTicket({ ticket_id: this.getTicket.id, data: { tags: tagsToSave } });
        if (response) this.cancelTags(tagsToSave);
      } catch (error) {
        console.error("ticket/saveTags: ", error);
      } finally {
        this.loadSaveTags = false;
      }
    },
    cancelTags(tags) {
      this.editTags = false;
      this.tags = this.setTags(tags || this.getTicket.tags);
    },
    removeTag(item) {
      this.tags.splice(this.tags.indexOf(item), 1);
      this.tags = [...this.tags];
    },
    async saveAgent(agent_id) {
      try {
        this.loadSaveAgent = true;

        await this.setTicketAgent({ ticket_id: this.getTicket.id, agent_id: agent_id });
      } catch (error) {
        console.error("ticket/saveAgent: ", error);
        this.agentTicket = this.getTicket.agent_id;
      } finally {
        this.loadSaveAgent = false;
      }
    },
  },
};
</script>
<style>
.v-card__title .v-toolbar__content {
  padding: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
}
.sticky-75 {
  position: sticky;
  top: 75px;
  z-index: 1;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-bottom: 0;
}
.v-messages.theme--light {
  display: none;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.h-auto {
  height: auto !important;
}
.v-toolbar-info .v-toolbar__content {
  padding: 0 !important;
  height: 58px !important;
}
</style>
