<template>
  <v-app id="login">
    <v-content
      class="fondo flex align-center"
      :style="{ 'background-image': 'url(' + require('@/assets/fondo.png') + ')' }"
    >
      <v-container style="min-height:400px">
        <v-row>
          <v-col class="text-center text-sm-left mt-5 col-xs-12 col-md-8">
            <div class="ma-5 px-2">
              <h1>Apolo CRM</h1>
              <h3 class="font-weight-thin title pt-2 pb-5">Plataforma de administración de Leads</h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-5" cols="12" sm="6" md="4">
            <v-form ref="formSignin" lazy-validation>
              <v-row class="px-5">
                <v-col cols="12">
                  <v-text-field
                    v-model="user.email"
                    :rules="rules.email"
                    label="Correo electrónico"
                    placeholder=" "
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="user.password"
                    :rules="rules.required"
                    label="Contraseña"
                    placeholder=" "
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col v-if="error" class="error--text pb-2" cols="12">{{ error }}</v-col>
                <v-col cols="12">
                  <v-btn
                    rounded
                    color="primary"
                    class="white--text font-weight-bold px-4"
                    :loading="loading"
                    @click="loginUser"
                    >Iniciar sesion</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" class="pl-5 ml-1">
            <v-btn
              text
              small
              rounded
              color="primary"
              @click="
                dialogRecovery = true;
                resetForm();
              "
            >
              Recuperar contraseña
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialogRecovery" max-width="400" persistent>
        <v-card class="pa-2">
          <v-col>
            <h3 class="font-weight-thin title pt-2 pb-5">Recuperar contraseña</h3>
            <h5>
              Ingrese su correo electrónico y le enviaremos un correo con un link para que cambie su contraseña
            </h5>
          </v-col>
          <v-col>
            <v-form ref="formRecovery" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="rules.email"
                label="Correo electrónico"
                placeholder=" "
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col v-if="errorSend" class="error--text pb-2" cols="12">{{ errorSend }}</v-col>
          <v-col class="text-center">
            <v-btn rounded color="primary" :loading="sending" @click="send">
              Enviar
            </v-btn>
            <v-btn rounded text @click="dialogRecovery = false">
              Cancelar
            </v-btn>
          </v-col>
          <v-col v-if="successSend">
            <v-alert dense text type="success">
              Se envió con éxito el correo para reestablecer tu contraseña
            </v-alert>
          </v-col>
        </v-card>
      </v-dialog>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      email: "",
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
      },
      loading: false,
      error: null,
      dialogRecovery: false,
      errorSend: null,
      successSend: false,
      sending: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent"]),
  },
  methods: {
    ...mapActions(["login", "recoveryPassword", "setAgent", "setAgentAuthenticated"]),
    async loginUser() {
      this.loading = true;
      if (this.$refs.formSignin.validate()) {
        let response = await this.login({ user: this.user });
        if (!response.data) {
          this.error = this.showErrorFirebase(response.error.code);
        }
      }
      this.loading = false;
    },
    async send() {
      try {
        this.sending = true;
        this.successSend = false;
        this.errorSend = false;

        if (this.$refs.formRecovery.validate()) {
          let response = await this.recoveryPassword({ email: this.email });

          if (response.error) throw response.error.code;

          this.successSend = true;
          this.resetForm();
        }
      } catch (e) {
        this.errorSend = this.showErrorFirebase(e);
      } finally {
        this.sending = false;
      }
    },
    showErrorFirebase(code) {
      let text = "";
      switch (code) {
        case "auth/wrong-password":
          text = "Contraseña incorrecta";
          break;
        case "auth/user-not-found":
          text = "El correo electrónico no está registrado";
          break;
      }
      return text;
    },
    resetForm() {
      this.email = "";
      if (this.$refs.formRecovery) this.$refs.formRecovery.resetValidation();
    },
  },
};
</script>
<style scoped>
.fondo {
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
}
</style>
